import { ActionTypes } from "../constants/ActionTypes";

const initialState ={
   sidebar_status: window.innerWidth <= 1200 ? "close" : "open",
   selected_key:  ['1'],
   unseen_message:""
}

export const sidebarStatusReducer = (state = initialState.sidebar_status,{type,payload})=>{
    switch(type){
        case ActionTypes.SET_SIDEBAR_STATUS:
            return {...state,sidebar_status:payload};
        default :
            return state;
    }
}


export const unseenMessageReducer = (state = initialState.unseen_message,{type,payload})=>{
    switch(type){
        case ActionTypes.UNSEEN_MESSAGE:
            return {...state,unseen_message:payload};
        default :
            return state;
    }
}
