import React, { useEffect, useState } from "react";
import { BsTruckFlatbed, BsTruck } from "react-icons/bs";
import { HiUser } from "react-icons/hi";
import { ImTruck } from "react-icons/im";
import { GiRoad } from "react-icons/gi";
import { FaTruckLoading } from "react-icons/fa";
import DashCard from "./../../components/DashCard";
import { message } from "antd";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ActionTypes } from "../../redux/constants/ActionTypes";

import axios from "axios";
import TripDashInfo from "../../components/TripDashInfo";
import { Link } from "react-router-dom";

function AdminDashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dashData, setDashData] = useState({});
  const [tripInfo, setTripInfo] = useState([]);

  var isLoggedIn = localStorage.getItem("loggedIn");

  var data = null;
  if (isLoggedIn) {
    data = JSON.parse(localStorage.getItem("adminInfo"));
  } else {
    localStorage.clear();
    dispatch({ type: ActionTypes.ADMIN_LOGOUT });
    navigate("/");
  }

  // FETCH ADMIN DATA

  const fetchData = () => {
    message.loading({ content: "Loading..", key: "loading" });
    axios
      .post(`${process.env.REACT_APP_API_URL}/admin/admin_dash_data`, {
        id: data._id,
      })
      .then((res) => {
        if (res.data.status) {
          setDashData(res.data.data);
          setTripInfo(res.data.data.onWayTripInfo);
          message.success({ content: "Data Loaded", key: "loading" });
        } else {
          message.error({ content: res.data.message, key: "loading" });
        }
      });
  };

  useEffect(() => {
    //ADMIN DATA
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6 col-lg-3 my-2">
          {/* REGISTERED SUPERVISORS AND ADMINS */}

          <DashCard
            icon={<HiUser />}
            infoCount={dashData.users ? dashData.users : 0}
            infoTitle={"Total Users"}
            iconBg={"card-gradiant-info"}
            linkPath={"/userdetails"}
            linkTitle={"view more"}
            activeUser={dashData.activeUsers ? dashData.activeUsers : 0}
          />
        </div>
        <div className="col-md-6 col-lg-3 my-2">
          {/* WAITING FOR LOADING TRUCKS */}

          <DashCard
            icon={<BsTruckFlatbed />}
            infoCount={dashData.waitingTrucks ? dashData.waitingTrucks : 0}
            infoTitle={"Waiting for loading"}
            iconBg={"card-gradiant-orange"}
            linkPath={"/waitingtruck"}
            linkTitle={"view more"}
          />
        </div>
        <div className="col-md-6 col-lg-3 my-2">
          {/* LOADED TRUCKS */}

          <DashCard
            icon={<ImTruck />}
            infoCount={dashData.loadedTrucks ? dashData.loadedTrucks : 0}
            infoTitle={"Loaded Truck"}
            iconBg={"card-gradiant-green"}
            linkPath={"/truckdetails"}
            linkTitle={"view more"}
          />
        </div>
        <div className="col-md-6 col-lg-3 my-2">
          {/* CANCELED TRUCKS */}

          <DashCard
            icon={<BsTruck />}
            infoCount={dashData.cancelTrucks ? dashData.cancelTrucks : 0}
            infoTitle={"Cancel Truck"}
            iconBg={"card-gradiant-red"}
            linkPath={"/truckdetails"}
            linkTitle={"view more"}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-3 my-2">
          {/* READY TRIPS */}

          <DashCard
            icon={<BsTruckFlatbed />}
            infoCount={dashData.readyTrips ? dashData.readyTrips : 0}
            infoTitle={"Ready Trips"}
            iconBg={"card-gradiant-info"}
            linkPath={"/readytrips"}
            linkTitle={"view more"}
          />
        </div>
        <div className="col-md-6 col-lg-3 my-2">
          {/* ON THE WAY TRUCKS */}

          <DashCard
            icon={<GiRoad />}
            infoCount={dashData.onWayTrips ? dashData.onWayTrips : 0}
            infoTitle={"On the way "}
            iconBg={"card-gradiant-orange"}
            linkPath={"/onwaytrips"}
            linkTitle={"view more"}
          />
        </div>
        <div className="col-md-6 col-lg-3 my-2">
          {/* ARRIVED TRIPS */}

          <DashCard
            icon={<BsTruckFlatbed />}
            infoCount={dashData.arrivedTrips ? dashData.arrivedTrips : 0}
            infoTitle={"Arrived Trips"}
            iconBg={"card-gradiant-info"}
            linkPath={"/tripdetails"}
            linkTitle={"view more"}
          />
        </div>
        <div className="col-md-6 col-lg-3 my-2">
          {/* COMPLETED TRIPS */}

          <DashCard
            icon={<BsTruckFlatbed />}
            infoCount={dashData.completeTrips ? dashData.completeTrips : 0}
            infoTitle={"Completed Trips"}
            iconBg={"card-gradiant-green"}
            linkPath={"/tripdetails"}
            linkTitle={"view more"}
          />
        </div>
        <div className="col-md-6 col-lg-3 my-2">
          {/*  REDIRECTED TRIPS */}

          <DashCard
            icon={<FaTruckLoading />}
            infoCount={dashData.redirectedTrips ? dashData.redirectedTrips : 0}
            infoTitle={"Redirected Trips"}
            iconBg={"card-gradiant-orange"}
            linkPath={"/tripdetails"}
            linkTitle={"view more"}
          />
        </div>
        <div className="col-md-6 col-lg-3 my-2">
          {/* CANCELED TRIPS */}

          <DashCard
            icon={<FaTruckLoading />}
            infoCount={dashData.cancelTrips ? dashData.cancelTrips : 0}
            infoTitle={"Cancel Trips"}
            iconBg={"card-gradiant-red"}
            linkPath={"/tripdetails"}
            linkTitle={"view more"}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="form-card back-white mt-4 pb-4">
            <div className="dash-trip-heading card-gradiant-theme d-flex align-items-center justify-content-between">
              <p className="d-inline">On The Way Trips </p>
              <Link
                to="/arrivedtrips"
                className="btn text-white fw-500 outline-white"
              >
                Arrived Trips
              </Link>
            </div>
            <div className="row">
              {tripInfo.length > 0 ? (
                tripInfo.map((element) => {
                  return (
                    <TripDashInfo
                      key={element._id}
                      firm={
                        element.firm
                          ? element.firm[0]
                            ? element.firm[0]
                            : ""
                          : ""
                      }
                      truck={
                        element.truckInfo
                          ? element.truckInfo[0]
                            ? element.truckInfo[0]
                            : ""
                          : ""
                      }
                      trip={element.trip ? element.trip : ""}
                    />
                  );
                })
              ) : (
                <div className="row">
                  <div className="col-md-4 mx-auto form-card back-white trip-card">
                    <p className="text-center">No Trips Available </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;
