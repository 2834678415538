import React,{useState} from "react";
import { Form, Input, Button, DatePicker, Select, message } from "antd";
import axios from "axios";
import moment from "moment";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { useNavigate } from 'react-router-dom';

const { Option } = Select;

function AddUser() {
  const [form] = Form.useForm();
  const [loading,setLoading] = useState(false)
  const navigate = useNavigate()

  const onFinish = (values) => {
    if (values.password === values.confirm_password) {
      setLoading(true)
      const userData = {
        name: values.name.trim(),
        password:values.password.trim(),
        mobile: values.mobile,
        email: values.email.trim(),
        dob: values.dob? moment(values.dob).format("YYYY-MM-DD") : "",
        role: values.role.trim()
        
      };

      axios
        .post(`${process.env.REACT_APP_API_URL}/admin/create_admin`, userData)
        .then((res) => {
          if (res.data.status) {
            message.success({ content: res.data.message, key: "some" });
            form.resetFields();
            setLoading(false);
            navigate('/userdetails')
            
            // alert(res.data.message)
          } else {
            message.error({ content: res.data.message, key: "some" });
            setLoading(false)
          }
          
        });
    } else {
      message.error({content : "password doesn't match",key : "some"});
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="form-card bg-white">
            <div className="card-heading">
              <p>Add New User</p>
            </div>
            <div>
              <Form
                form={form}
                name="form"
                initialValues={{
                  remember: true
                }}
                onFinish={onFinish}
                layout="vertical"
                autoComplete="off"
              >
                <div className="row">
                  <div className="col-md-6">
                    <Form.Item
                      label="User Name"
                      name="name"
                      rules={[
                        {
                          pattern:/^[a-zA-Z0-9_ ]+$/,
                          message : "User name can contain alphanumeric characters only!"
                        },
                        {
                          required: true,
                          message: "Please input user name!"
                        }
                      ]}
                    >
                      <Input placeholder="Enter Username " />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item
                      label="Mobile"
                      name="mobile"
                      rules={[
                        {
                          pattern: /^[0-9]{10}$/,
                          message: "Input valid mobile number!"
                        },
                        {
                          required: true,
                          message: "Please input your mobile number!"
                          // whitespace:true,
                        }
                      ]}
                    >
                      <Input type="number" placeholder="Enter Your Mobile" />
                    </Form.Item>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 ">
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        {
                          type: "email",
                          message: "Please input valid email!"
                        },
                        {
                          required: true,
                          message: "Please input your email!"
                        }
                      ]}
                    >
                      <Input placeholder="Enter Your Email" />
                    </Form.Item>
                  </div>
                  <div className="col-md-6 ">
                    <Form.Item
                      label="Date of Birth"
                      name="dob"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please input your dob!"
                      //   }
                      // ]}
                    >
                      <DatePicker
                        placeholder="Enter your dob"
                        className="w-100"
                      />
                      {/* <Input placeholder="Enter your username "/> */}
                    </Form.Item>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Item
                      name="password"
                      className="form-item"
                      label="Password"
                      rules={[
                        {
                          pattern:
                            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                          message:
                            "password must contain minimum eight characters, at least one letter, one number and one special character."
                        },
                        {
                          required: true,
                          message: "Please input your password!",
                          whitespace: true
                        }
                      ]}
                    >
                      <Input.Password placeholder="Enter Password" />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item
                      name="confirm_password"
                      className="form-item"
                      label="Confirm Password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                          whitespace: true
                        }
                      ]}
                    >
                      <Input.Password placeholder="Enter Confirm Password"/>
                    </Form.Item>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Item
                      label="Role"
                      name="role"
                      rules={[
                        {
                          required: true,
                          message: "Please input your role!"
                        }
                      ]}
                    >
                      {/* <Input placeholder="Enter Your Mobile" /> */}
                      <Select placeholder="Select Role" className="w-100">
                        <Option value="admin">Admin</Option>
                        <Option value="subadmin">Sub Admin</Option>
                        <Option value="supervisor">Supervisor</Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>

                {/* <div className="row">
                  <div className="card-heading">
                    <p>Address</p>
                  </div>
                </div> */}

                <div className="col-md-6">
                  <Form.Item>
                    <Button htmlType="submit" className="form-btn border-0">
                     {loading?<LoadingSpinner/>:"Submit"} 
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddUser;
