import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {Provider} from "react-redux";

import store from './redux/store';
import axios from "axios"
import { SocketContext,Socket } from './Socket/Socket';

axios.defaults.headers.common['Authorization'] = 'Bearer '+ localStorage.getItem('authToken')
axios.defaults.withCredentials = true;


ReactDOM.render(
  <Provider store ={store}>
    <SocketContext.Provider value = {Socket} >
        <App />
    </SocketContext.Provider>
   
  </Provider>,
  document.getElementById('root')
);



