import React from 'react'

function Footer() {
    return (
        <footer className="container-fluid bg-white">
          <div className="row">
              <div className="col-md-12 footer ">
                    <p>© Copyright {new Date().getFullYear()} - AS-SME - All rights reserved | Design And Developed By - <a href="https://techoniqfusion.com/" target="_blank" rel = "noreferrer">  Techoniq Fusion IT Solutions PVT. LTD.</a></p>
              </div>
          </div>  
        </footer>
    )
}

export default Footer
 