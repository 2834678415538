import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  message,
  Upload,
  Modal,
  Image,
  Typography,
  Divider,
  Space,
} from "antd";
import { AiOutlineCamera } from "react-icons/ai";
import axios from "axios";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ActionTypes } from "../../../redux/constants/ActionTypes";

import { useParams } from "react-router-dom";

const { Option } = Select;

const { TextArea } = Input;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function EditTrip() {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const params = useParams();

  const [form] = Form.useForm();

  const [tripData, setTripData] = useState([]);
  const [firmData, setFirmData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [supplierName, setSupplierName] = useState([
    "NDO GLOBAL INFRA ENERGY LTD.",
    "HRI SHIV STONE MANKI",
    "HRI VINAYAK STONE MANKI",
    "ARYANA GRITUDYOG",
    "HREE BALAJI STONE BIWAN",
    "HRI RUDRA MINING AND MINERALS",
    "ISHINU GRIT UDYOG BIWAN",
    "HRI RAM STONE BIWAN",
    "HRIJI STONE BIWAN",
    "JHIRKESHWAR MAHADEV STONE COMPANY",
    "SHRI SHIV STONE CRUHIER NANGAL",
    "SINGLA & SONS",
    "YAGNA INFRA",
    "KGN BUILDING MATERIAL",
    "NINE BROTHERS",
    "NBTRAILERS",
    "TANDP AGGREGATES ASSOCIATE",
    "JB STONE CRUSHERS",
    "JKY STONE CRUSHING COMPANY",
    "SHRI HARI STONE GRITS PVT.LTD LALAWANDI",
    "ARAVALI GRIT UDYOG",
    "ANVI CONSTRUCTION COMPANY",
  ]);
  const [supplier, setSupplier] = useState("");

  const [materialDetails, setMaterialDetails] = useState([
    "9mm",
    "10mm",
    "16mm",
    "20mm",
    "40mm",
    "65mm",
    "dust",
    "GSB",
    "M Sand",
    "Course sand",
    "Filling sand",
    "Fine sand",
  ]);
  const [material, setMaterial] = useState("");

  const [materialTypes, setMaterialTypes] = useState([
    "VSI",
    "SMA",
    "WMM",
    "RMC",
    "RMC/MIX",
  ]);
  const [materialT, setMaterialT] = useState("");

  // SUPPLIER DETAILS
  const addItem = (e) => {
    if (supplier !== "") {
      e.preventDefault();
      setSupplierName([...supplierName, supplier]);
      setSupplier("");
    }
  };

  const onSupplierNameChange = (event) => {
    setSupplier(event.target.value);
  };

  // MATERIAL DETAILS

  const addMaterialDetails = (e) => {
    if (material !== "") {
      e.preventDefault();
      setMaterialDetails([...materialDetails, material]);
      setMaterial("");
    }
  };

  const onMaterialDetailsChange = (event) => {
    setMaterial(event.target.value);
  };

  //   MATERIAL TYPE

  const addMaterialType = (e) => {
    if (materialT !== "") {
      e.preventDefault();
      setMaterialTypes([...materialTypes, materialT]);
      setMaterialT("");
    }
  };

  const onMaterialTypeChange = (event) => {
    setMaterialT(event.target.value);
  };

  const [optionalImg, setOptionalImg] = useState([]);

  const [invoiceFile, setInvoiceFile] = useState([]);

  const [weightSlip, setWeightSlip] = useState([]);

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  var isLoggedIn = localStorage.getItem("loggedIn");

  var data = null;
  if (isLoggedIn) {
    // eslint-disable-next-line no-unused-vars
    data = JSON.parse(localStorage.getItem("adminInfo"));
  } else {
    localStorage.clear();
    dispatch({ type: ActionTypes.ADMIN_LOGOUT });
    navigate("/login");
  }

  // UPLOAD INVOICE FILE

  const onUploadInvoiceFile = ({ fileList: newFileList }) => {
    setInvoiceFile(newFileList);
  };

  // UPLOAD WEIGHBRIDGE SLIP

  const onUploadWeightSlip = ({ fileList: newFileList }) => {
    setWeightSlip(newFileList);
  };

  // UPLOAD OPTIONAL IMAGE
  const onUploadOptionalImg = ({ fileList: newFileList }) => {
    setOptionalImg(newFileList);
  };

  // HANDLE MODAL PREVIEW

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.preview);
    setPreviewVisible(true);
  };

  // MODAL PREVIEW CANCEL

  const handleCancel = () => setPreviewVisible(false);

  // ON LOAD TRIP

  const onFinish = (values) => {
    setLoading(true);

    let loadTripData = new FormData();

    loadTripData.append("firm", values.firmname.trim());
    loadTripData.append("supervisor", data._id.trim());
    loadTripData.append("truck", values.truck.trim());
    loadTripData.append("trip", values.trip.trim());
    loadTripData.append("supplier", values.supplier.trim());
    loadTripData.append("material_details", values.material_details.trim());
    loadTripData.append("invoice_no", values.invoice_no.trim());
    loadTripData.append("tp_no", values.tp_number.trim());
    loadTripData.append("tp_weight", values.tp_weight.trim());
    loadTripData.append("tw_measure", values.tw_measure.trim());
    loadTripData.append("lw_measure", values.lw_measure.trim());
    loadTripData.append("loading_weight", values.loading_weight.trim());
    loadTripData.append("mr_no", values.mr_number.trim());
    loadTripData.append("id", params.id.trim());

    if (values.invoice_file) {
      loadTripData.append("invoice_file", values.invoice_file.file);
    }

    if (values.weight_bridge_slip) {
      loadTripData.append("weight_bridge_slip", values.weight_bridge_slip.file);
    }

    if (values.optional_img) {
      loadTripData.append("optional_img", values.optional_img.file);
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}/admin/edit_trip`, loadTripData)
      .then((res) => {
        if (res.data.status) {
          // fetchWaitingTrucks()
          message.success({key:"loading",content:res.data.message});
          fetchTripDetails();
          setWeightSlip([]);
          setInvoiceFile([]);
          setLoading(false);
        } else {
          message.error({key:"loading",content:res.data.message});
          setLoading(false);
        }
      });
  };

  // FETCH FIRMS

  const fetchFirmDetails = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/admin/get_firmData`)
      .then((res) => {
        if (res.data.status) {
          var firm = [];

          res.data.data.forEach((element, index) => {
            firm.push(
              <Option key={index + 1} value={element._id}>
                {element.name}
              </Option>
            );
          });

          setFirmData(firm);
        } else {
          message.success({key:"loading",content:res.data.message});
        }
      });
  };

  // FETCH TRIP DETAILS

  const fetchTripDetails = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/admin/fetch_trip_by_id`, {
        id: params.id,
      })
      .then((res) => {
        if (res.data.status) {
          setTripData(res.data.data[0]);
        } else {
          message.success({key:"loading",content:res.data.message});
        }
      });
  };

  useEffect(() => {
    fetchTripDetails();
    fetchFirmDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      truck: tripData.truckName
        ? tripData.truckName[0]
          ? tripData.truckName[0]
          : ""
        : "",
      firmname: tripData.firm ? tripData.firm : null,
      trip: tripData.trip ? tripData.trip : null,
      supplier: tripData.supplier ? tripData.supplier : null,
      material_details: tripData.material_details
        ? tripData.material_details
        : null,
      material_type: tripData.material_type ? tripData.material_type : null,
      invoice_no: tripData.invoice_no ? tripData.invoice_no : null,
      loading_weight: tripData.loading_weight ? tripData.loading_weight : null,
      tw_measure: tripData.tw_measure ? tripData.tw_measure : null,
      lw_measure: tripData.lw_measure ? tripData.lw_measure : null,
      tp_weight: tripData.tp_weight ? tripData.tp_weight : null,
      tp_number: tripData.tp_no ? tripData.tp_no : null,
      mr_number: tripData.mr_no ? tripData.mr_no : null,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripData]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
            <img alt="example" style={{ width: "100%" }} src={previewImage} />
          </Modal>
          <div className="form-card bg-white">
            <div className="card-heading">
              <p>Edit Trip</p>
            </div>
            <div>
              <Form
                form={form}
                name="form"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                layout="vertical"
                autoComplete="off"
              >
                <div className="row">
                  <div className="col-md-6">
                    <Form.Item
                      name="firmname"
                      label="Firm Name"
                      rules={[
                        {
                          required: true,
                          message: "Please select firm name!",
                        },
                      ]}
                    >
                      <Select placeholder="Select Firm">{firmData}</Select>
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item
                      name="truck"
                      label="Truck"
                      rules={[
                        {
                          required: true,
                          message: "Please select truck!",
                        },
                      ]}
                    >
                      <Input placeholder="Truck" disabled />
                    </Form.Item>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Item
                      name="trip"
                      label="Trip/Route"
                      rules={[
                        {
                          pattern: /^[a-zA-Z0-9_ ]+$/,
                          message:
                            "Trip/Route can contain alpha numeric characters only!",
                        },
                        {
                          required: true,
                          message: "Please input trip details!",
                        },
                      ]}
                    >
                      <TextArea
                        autoSize={{ minRows: 3, maxRows: 3 }}
                        placeholder="Enter Trip/Route"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-3">
                    <Form.Item
                      name="material_details"
                      label="material details"
                      rules={[
                        {
                          required: true,
                          message: "Please input material details!",
                        },
                      ]}
                    >
                      <Select
                        // style={{ width: 300 }}
                        placeholder="Select Material Details"
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider style={{ margin: "8px 0" }} />
                            <Space
                              align="center"
                              style={{ padding: "0 8px 4px" }}
                            >
                              <Input
                                placeholder="Please enter item"
                                style={{ width: "100%" }}
                                value={material}
                                onChange={onMaterialDetailsChange}
                              />
                              <Typography.Link
                                onClick={addMaterialDetails}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Add Other
                              </Typography.Link>
                            </Space>
                          </>
                        )}
                      >
                        {materialDetails.map((item) => (
                          <Option key={item}>{item}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="col-md-3">
                    <Form.Item
                      name="material_type"
                      label="material type"
                      rules={[
                        {
                          required: true,
                          message: "Please select material type!",
                        },
                      ]}
                    >
                      <Select
                        // style={{ width: 300 }}
                        placeholder="Select Material Type"
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider style={{ margin: "8px 0" }} />
                            <Space
                              align="center"
                              style={{ padding: "0 8px 4px" }}
                            >
                              <Input
                                placeholder="Please enter item"
                                style={{ width: "100%" }}
                                value={materialT}
                                onChange={onMaterialTypeChange}
                              />
                              <Typography.Link
                                onClick={addMaterialType}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Add Other
                              </Typography.Link>
                            </Space>
                          </>
                        )}
                      >
                        {materialTypes.map((item) => (
                          <Option key={item}>{item}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="col-md-12">
                      <Form.Item
                        name="invoice_no"
                        label="Invoice Number"
                        rules={[
                          {
                            pattern: /[0-9]+$/,
                            message: "Only numeric values or digits allowed!",
                          },
                          {
                            required: true,
                            message: "Please input invoice no!",
                          },
                        ]}
                      >
                        <Input placeholder="Enter Invoice No" />
                      </Form.Item>
                    </div>
                    <div className="col-md-12 d-flex align-items-center">
                      <Form.Item name="invoice_file" label="Invoice File">
                        <Upload
                          accept="image/*"
                          listType="picture-card"
                          fileList={invoiceFile}
                          onPreview={handlePreview}
                          onChange={onUploadInvoiceFile}
                          beforeUpload={() => false}
                        >
                          {invoiceFile.length < 1 && (
                            <AiOutlineCamera className="fs-6 me-2" />
                          )}
                        </Upload>
                      </Form.Item>
                      {tripData.invoice_file ? (
                        <Image
                          src={
                            process.env.REACT_APP_API_URL +
                            "/" +
                            tripData.invoice_file
                          }
                          width={"70px"}
                          height={"80px"}
                          alt="invoice file"
                        />
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="col-md-12">
                      <Form.Item label="Loading Weight">
                        <Input.Group compact>
                          <Form.Item
                            name={"loading_weight"}
                            noStyle
                            rules={[
                              {
                                pattern: /[0-9]+$/,
                                message:
                                  "Only numeric values or digits allowed!",
                              },
                              {
                                required: true,
                                message: "Loading weight is required",
                              },
                            ]}
                          >
                            <Input
                              style={{ width: "60%" }}
                              type="number"
                              placeholder="Enter Loading Weight"
                            />
                          </Form.Item>
                          <Form.Item
                            name={"lw_measure"}
                            noStyle
                            rules={[
                              {
                                required: true,
                                message: "Measurement is required",
                              },
                            ]}
                            initialValue={
                              tripData.lw_measure ? tripData.lw_measure : ""
                            }
                          >
                            <Select
                              placeholder="Select Measurement Unit"
                              style={{ width: "40%" }}
                            >
                              <Option value="mt"> MT </Option>
                              <Option value="cum"> CUM </Option>
                              <Option value="pcs"> PCS </Option>
                            </Select>
                          </Form.Item>
                        </Input.Group>
                      </Form.Item>
                    </div>
                    <div className="col-md-12 d-flex align-items-center">
                      <Form.Item
                        name="unload_weighbridge"
                        label="Weighbridge Slip"
                      >
                        <Upload
                          accept="image/*"
                          listType="picture-card"
                          fileList={weightSlip}
                          onPreview={handlePreview}
                          onChange={onUploadWeightSlip}
                          beforeUpload={() => false}
                        >
                          {weightSlip.length < 1 && (
                            <AiOutlineCamera className="fs-6 me-2" />
                          )}
                        </Upload>
                      </Form.Item>
                      {tripData.weight_bridge_slip ? (
                        <Image
                          src={
                            process.env.REACT_APP_API_URL +
                            "/" +
                            tripData.weight_bridge_slip
                          }
                          width={"70px"}
                          height={"80px"}
                          alt="invoice file"
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Item
                      name="tp_number"
                      label="TP Number"
                      rules={[
                        {
                          pattern: /^[a-zA-Z0-9_ ]+$/,
                          message:
                            "TP No can contain alpha numeric characters!",
                        },
                        {
                          required: true,
                          message: "Please input tp no!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter TP Number"
                        onInput={(e) =>
                          (e.target.value = e.target.value.toUpperCase())
                        }
                      />
                    </Form.Item>
                  </div>

                  <div className="col-md-6">
                    <Form.Item label="TP Weight">
                      <Input.Group compact>
                        <Form.Item
                          name={"tp_weight"}
                          noStyle
                          rules={[
                            {
                              pattern: /[0-9]+$/,
                              message: "Only numeric values or digits allowed!",
                            },
                            {
                              required: true,
                              message: "TP weight is required!",
                            },
                          ]}
                          initialValue={
                            tripData.tp_weight ? tripData.tp_weight : ""
                          }
                        >
                          <Input
                            style={{ width: "60%" }}
                            type="number"
                            placeholder="Enter TP Weight"
                          />
                        </Form.Item>
                        <Form.Item
                          name={"tw_measure"}
                          noStyle
                          rules={[
                            {
                              required: true,
                              message: "Measurment is required",
                            },
                          ]}
                          initialValue={
                            tripData.tw_measure ? tripData.tw_measure : ""
                          }
                        >
                          <Select
                            placeholder="Select Measurement Unit"
                            style={{ width: "40%" }}
                          >
                            <Option value="mt"> MT </Option>
                            <Option value="cum"> CUM </Option>
                            <Option value="pcs"> PCS </Option>
                          </Select>
                        </Form.Item>
                      </Input.Group>
                    </Form.Item>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <Form.Item
                      name="mr_number"
                      label="MR Number"
                      rules={[
                        {
                          pattern: /^[a-zA-Z0-9_ ]+$/,
                          message:
                            "MR No can contain alpha numeric characters only!",
                        },
                        {
                          required: true,
                          message: "Please input MR number!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter MR Number"
                        onInput={(e) =>
                          (e.target.value = e.target.value.toUpperCase())
                        }
                      />
                    </Form.Item>
                  </div>

                  <div className="col-md-6">
                    <Form.Item
                      name="supplier"
                      label="Supplier Name"
                      rules={[
                        {
                          required: true,
                          message: "Please input Supplier!",
                        },
                      ]}
                    >
                      <Select
                        // style={{ width: 300 }}
                        placeholder="Select Supplier Name"
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider style={{ margin: "8px 0" }} />
                            <Space
                              align="center"
                              style={{ padding: "0 8px 4px" }}
                            >
                              <Input
                                placeholder="Please enter item"
                                style={{ width: "100%" }}
                                value={supplier}
                                onChange={onSupplierNameChange}
                              />
                              <Typography.Link
                                onClick={addItem}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                Add Other
                              </Typography.Link>
                            </Space>
                          </>
                        )}
                      >
                        {supplierName.map((item) => (
                          <Option key={item}>{item}</Option>
                        ))}
                      </Select>
                      {/* <Input placeholder = "Enter Suppplier Name" /> */}
                    </Form.Item>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 d-flex align-items-center">
                    <Form.Item label="Optional Pic" name="optional_img">
                      <Upload
                        accept="image/*"
                        listType="picture-card"
                        fileList={optionalImg}
                        onPreview={handlePreview}
                        onChange={onUploadOptionalImg}
                        beforeUpload={() => false}
                      >
                        {optionalImg.length < 1 && (
                          <AiOutlineCamera className="fs-6 me-2" />
                        )}
                      </Upload>
                    </Form.Item>
                    {tripData.optional_img ? (
                      <Image
                        src={
                          process.env.REACT_APP_API_URL +
                          "/" +
                          tripData.optional_img
                        }
                        width={"70px"}
                        height={"80px"}
                        alt="invoice file"
                      />
                    ) : null}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="col-md-6">
                      <Form.Item>
                        <Button htmlType="submit" className="form-btn border-0">
                          {loading ? <LoadingSpinner /> : "Submit"}
                        </Button>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditTrip;
