import { BrowserRouter, Route, Routes,Navigate } from "react-router-dom";
import MasterLayout from "./layout/MasterLayout";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./style/main.scss"
import 'antd/dist/antd.min.css'
import Login from './pages/auth/Login';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import {useSelector} from "react-redux";
import Profile from './pages/dashboard/Profile';
import FirmMangement from './pages/dashboard/FirmMangement';
import AddUser from './pages/dashboard/usermanagement/AddUser';
import UserDetails from './pages/dashboard/usermanagement/UserDetails';
import EditUser from './pages/dashboard/usermanagement/EditUser';
import ChangePassword from './pages/dashboard/ChangePassword';
import UnauthorizedMessage from "./pages/UnauthorizedMessage";
import AddTruck from './pages/supervisor/truckmanagement/AddTruck';
import TruckDetails from './pages/supervisor/truckmanagement/TruckDetails';
import EditTruck from './pages/supervisor/truckmanagement/EditTruck';
import LoadTrip from './pages/supervisor/tripmanagement/LoadTrip';
import TripDetails from './pages/supervisor/tripmanagement/TripDetails';
import ReadyTrips from './pages/supervisor/tripmanagement/ReadyTrips';
import OnWayTrips from './pages/supervisor/tripmanagement/OnWayTrips';
import EditTrip from './pages/supervisor/tripmanagement/EditTrip';
import AdminDashboard from "./pages/dashboard/AdminDashboard";
import SupervisorDashboard from "./pages/dashboard/SupervisorDashboard";
import WaitingTruck from './pages/dashboard/WaitingTruck';
import ArrivedTrip from './pages/supervisor/tripmanagement/ArrivedTrip';
import UnloadTrip from "./pages/supervisor/tripmanagement/UnloadTrip";
import MaterialInfo from './pages/dashboard/MaterialInfo';
import SendMessage from './pages/subadmin/SendMessage';
import Messages from './pages/supervisor/Messages';
import { useContext, useEffect } from "react";
import { SocketContext } from "./Socket/Socket";


function App() {

  
  const loggedIn = useSelector(state => state.auth.isLoggedIn)
  const adminData = useSelector(state => state.auth.admin)

  const socket = useContext(SocketContext)


  useEffect(()=>{
    socket.emit("update_user_socket_id",adminData?adminData._id?adminData._id:"":"")
  },[adminData,socket])

  return (

   <BrowserRouter>
		<Routes>

    <Route path = "login" element={!loggedIn ? <Login/> : <Navigate to ="/"/>}   />
    <Route path = "forgot_password" element={<ForgotPassword/>}   />
    <Route path = "reset_password/:id/:date" element={<ResetPassword/>}   />


		<Route path="/" element={loggedIn ? <MasterLayout/> :<Navigate to ="/login"/>}>

      {/* DASHBOARD */}

      <Route path = "/" element = {adminData ? adminData.role ==="admin" ? <AdminDashboard/>: <SupervisorDashboard/> : null}/>

      {/* USER MANAGEMENT */}
      <Route path="profile" element={<Profile />} />
      <Route path="changepassword" element={<ChangePassword  />} />
      <Route path="firm" element={adminData? adminData.role==="admin" || adminData.role === "subadmin"? <FirmMangement />:<UnauthorizedMessage/> :null} />
      <Route path="adduser" element={adminData? adminData.role==="admin"?<AddUser />:<UnauthorizedMessage/> :null} />
      <Route path="userdetails" element={adminData? adminData.role==="admin"?<UserDetails />:<UnauthorizedMessage/> :null} />
      <Route path="edituser/:id" element={adminData? adminData.role==="admin"?<EditUser />:<UnauthorizedMessage/> :null} />
      <Route path="materialinfo" element={adminData? adminData.role==="admin"?<MaterialInfo />:<UnauthorizedMessage/> :null} />


      {/* TRUCK MANAGEMENT */}
      <Route path="addtruck" element={adminData? adminData.role==="supervisor" || adminData.role === "subadmin"?<AddTruck />:<UnauthorizedMessage/> :null} />
      <Route path="truckdetails" element={<TruckDetails />} />
      <Route path="waitingtruck" element={adminData ? adminData.role === "admin" ?<WaitingTruck /> : <UnauthorizedMessage/> : null} />
      <Route path="edittruck/:id" element={<EditTruck />} />
      <Route path = "sendmessage" element = {adminData ? adminData.role === "subadmin" ? <SendMessage/> : <UnauthorizedMessage/> :  null}/>

      {/* TRIP MANAGEMENT */}
      <Route path="loadtrip" element={<LoadTrip />} />
      <Route path="edittrip/:id" element={<EditTrip />} />
      <Route path="tripdetails" element={<TripDetails />} />
      <Route path="readytrips" element={<ReadyTrips />} />
      <Route path="onwaytrips" element={<OnWayTrips />} />
      <Route path="arrivedtrips" element={<ArrivedTrip />} />
      <Route path="tripinfo/:id" element={<UnloadTrip />} />

      <Route path = "message" element = {adminData ? adminData.role === "supervisor" ? <Messages/> : <UnauthorizedMessage/> :  null}/>

		</Route>

		</Routes>
   </BrowserRouter>
  );
}

export default App;
