import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Image,
  message,
  Table,
  Modal,
  Upload,
  Popconfirm,
} from "antd";
import { AiOutlineDelete } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import { firmDataAction } from "./../redux/actions/DataAction";
import { FiEdit } from "react-icons/fi";
import LoadingSpinner from "./LoadingSpinner";
import { useNavigate } from "react-router-dom";
import { ActionTypes } from "./../redux/constants/ActionTypes";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
function FirmDataTable() {
  var isLoggedIn = localStorage.getItem("loggedIn");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  var data = null;
  if (isLoggedIn) {
    // eslint-disable-next-line no-unused-vars
    data = JSON.parse(localStorage.getItem("adminInfo"));
  } else {
    localStorage.clear();
    dispatch({ type: ActionTypes.ADMIN_LOGOUT });
    navigate("/");
  }

  const columns = [
    {
      title: "S_No",
      key: "sno",
      width: "20px",
      render: (text, object, index) => {
        return index + 1;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },

    {
      title: "Logo",
      dataIndex: "",
      key: "logo",
      render: (text, record) =>
        record.logo ? (
          <Image
            key={record._id}
            src={`${process.env.REACT_APP_API_URL}/${record.logo}`}
            width={"60px"}
          />
        ) : null,
    },
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      render: (text, record) => (
        <div key={record._id}>
          <Button
            className="btn btn-outline-primary btn-sm fw-500"
            onClick={() => showEditModal(record)}
          >
            <FiEdit />
          </Button>

          {data.role === "admin" ? (
            <Popconfirm    title="Are you sure to delete this firm?"
            description="Are you sure to delete this user?"
            onConfirm={() => deleteFirm(record._id)}
            okText="Yes"
            cancelText="No">
            <Button className="btn ms-2 btn-sm fw-500">
              <AiOutlineDelete
                className="font-red fs-5"
                style={{ cursor: "pointer" }}
              />
            </Button>
            </Popconfirm>
           
          ) : null}
        </div>
      ),
    },
  ];

  const firmData = useSelector((state) => state.firmData);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [editFirmData, setEditFirmData] = useState([]);
  const [editFileList, setEditFileList] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  //  FETCH FIRM DATA
  const fetchFirmData = () => {
    message.loading({ content: "Loading...", key: "loading" });
    axios
      .get(`${process.env.REACT_APP_API_URL}/admin/get_firmData`)
      .then((res) => {
        if (res.data.status) {
          res.data.data.forEach((element, index) => {
            res.data.data[index].key = index;
          });

          dispatch(firmDataAction(res.data.data));
          message.success({ content: "Loaded", key: "loading" });
        } else {
          message.error(res.data.message);
        }
      });
  };

  // FIRM DELETE
  const deleteFirm = (firmId) => {
    message.loading({ content: "Delete...", key: "loading" });
    axios
      .post(`${process.env.REACT_APP_API_URL}/admin/delete_firm`, {
        id: firmId,
      })
      .then((res) => {
        if (res.data.status) {
          fetchFirmData();
          message.success({ content: "Deleted", key: "loading" });
        } else {
          message.error({content : "Failed to delete firm!" , key :"loading"});
        }
      });
  };

  // ON EDIT FORM SUBMIT
  const onEditData = (values) => {
    setLoading(true);
    let EditformData = new FormData();
    EditformData.append("name", values.name ? values.name.trim() : "");
    EditformData.append("email", values.email ? values.email.trim() : "");
    EditformData.append("mobile", values.mobile ? values.mobile.trim() : "");
    EditformData.append("address", values.address ? values.address.trim() : "");

    if (values.logo) {
      EditformData.append("logo", values.logo.file);
    }

    EditformData.append("id", editFirmData._id);

    axios
      .post(`${process.env.REACT_APP_API_URL}/admin/edit_firm`, EditformData)
      .then((res) => {
        if (res.data.status) {
          setEditFileList([]);
          handleModalCancel();
          fetchFirmData();
          message.success({ content: res.data.message, key: "loading" });
          setLoading(false);

          // alert(res.data.message)
        } else {
          message.error({ content: res.data.message, key: "loading" });
          setLoading(false);
        }
      });
  };

  // IMAGE PREVIEW
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.preview);
    setPreviewVisible(true);
  };

  //CANCEL IMAGE PREVIEW
  const handleCancel = () => setPreviewVisible(false);

  //EDIT FIRM LOGO
  const onEditLogo = ({ fileList: newFileList }) => {
    setEditFileList(newFileList);
  };

  //ON PREVIEW EDIT MODAL
  const showEditModal = (row) => {
    setEditFirmData(row);
    setIsModalVisible(true);
  };

  //ON CLOSE EDIT MODAL
  const handleModalCancel = () => {
    setIsModalVisible(false);
    setEditFirmData([]);
  };

  // SET VALUES IN EDIT FORM MODAL
  useEffect(() => {
    form.setFieldsValue({
      name: editFirmData.name ? editFirmData.name : "",
      email: editFirmData.email ? editFirmData.email : "",
      mobile: editFirmData.mobile ? editFirmData.mobile : "",
      address: editFirmData.address ? editFirmData.address : "",
    });
  }, [form, editFirmData]);
  return (
    <div>
      <Modal
        forceRender
        title="Edit Firm"
        visible={isModalVisible}
        onCancel={handleModalCancel}
        footer={false}
        getContainer={false}
      >
        <Modal
          visible={previewVisible}
          footer={null}
          getContainer={false}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
        <Form
          form={form}
          name="form"
          initialValues={{
            remember: true,
          }}
          onFinish={onEditData}
          layout="vertical"
          autoComplete="off"
        >
          <div className="row">
            <div className="col-md-12">
              <Form.Item label="Firm Logo" name="logo">
                <Upload
                  listType="picture-card"
                  fileList={editFileList}
                  onPreview={handlePreview}
                  onChange={onEditLogo}
                  beforeUpload={() => false}
                >
                  {editFileList.length < 1 && "+ Upload"}
                </Upload>
              </Form.Item>
              {editFirmData.logo ? (
                <Image src={editFirmData.logo} width={"100px"} />
              ) : null}
            </div>
            <div className="col-md-12 ">
              <Form.Item
                label="Firm Name"
                name="name"
                rules={[
                  {
                    pattern: /^[a-zA-Z_ ]+$/,
                    message: "Firm name must contain alpha characters only!",
                  },
                  {
                    required: true,
                    message: "Please input your name!",
                  },
                ]}
              >
                <Input placeholder="Enter your username " />
              </Form.Item>
            </div>
          </div>

          <div className="col-md-12 ">
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  type: "email",
                  message: "Please input valid email!",
                },
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <Input placeholder="Enter Your Email" />
            </Form.Item>
          </div>

          <div className="col-md-12 ">
            <Form.Item
              label="Mobile"
              name="mobile"
              rules={[
                {
                  pattern: /^[0-9]{10}$/,
                  message: "Input valid mobile number!",
                },
              ]}
            >
              <Input
                type="number"
                maxLength={10}
                placeholder="Enter Firm Mobile No. "
              />
            </Form.Item>
          </div>
          <div className="col-md-12 ">
            <Form.Item
              label="Firm Address"
              name="address"
              rules={[
                {
                  pattern: /^[a-zA-Z0-9_ ]+$/,
                  message:
                    "Firm Address can contain alphanumeric characters only!",
                },
              ]}
            >
              <Input.TextArea
                autoSize={{ minRows: 3, maxRows: 3 }}
                placeholder="Enter Firm Address "
              />
            </Form.Item>
          </div>

          <div className="col-md-12 ">
            <Form.Item>
              <Button htmlType="submit" className="form-btn border-0">
                {loading ? <LoadingSpinner /> : "Edit"}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
      <div>
        <Table
          className="bg-white"
          columns={columns}
          dataSource={firmData.data}
          size={"small"}
          pagination={false}
          style={{ overflow: "auto" }}
          expandable={{
            expandedRowRender: (record) => (
              <div className="row">
                <div className="col-md-12 text-center">
                  {record.mobile ? (
                    <p>
                      <span>Mobile : </span>
                      <span> {record.mobile}</span>
                    </p>
                  ) : (
                    ""
                  )}
                  {record.address ? (
                    <p>
                      <span>Address : </span>
                      <span> {record.address}</span>
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ),
          }}
        />
      </div>
    </div>
  );
}

export default FirmDataTable;
