
import { ActionTypes } from './../constants/ActionTypes';

const initialState = {
        socket_info : ""
}

export const socketReducer = (state = initialState.socket_info,{type,payload})=>{
        switch(type){
            case ActionTypes.SOCKET_INFO:
                return {...state,socket_info:payload};
            default :
                return state;
        }
    }