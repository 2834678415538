export const ActionTypes ={
    // states for admin panel layout
  
    SET_SIDEBAR_STATUS: "SET_SIDEBAR_STATUS",

    
    //Admin Login constants

    ADMIN_LOGIN_SUCCESS : "ADMIN_LOGIN_SUCCESS",
    ADMIN_LOGIN_FAILURE : "ADMIN_LOGIN_FAILURE",

    //Admin Logout constant
    ADMIN_LOGOUT:"ADMIN_LOGOUT",


    //FIRM DATA

    FIRM_DATA : "FIRM_DATA",

    WAITING_TRUCK_DATA : "WAITING_TRUCK_DATA",

    UNSEEN_MESSAGE : "UNSEEN_MESSAGE",

    //SOCKET INFO

    SOCKET_INFO : "SOCKET_INFO"

}