import React, { useContext, useState } from "react";
import {
  Form,
  Input,
  Upload,
  Modal,
  Checkbox,
  Button,
  message,
  Select
} from "antd";
import { AiOutlineCamera } from "react-icons/ai";
import LoadingSpinner from "./LoadingSpinner";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ActionTypes } from "./../redux/constants/ActionTypes";
import axios from "axios";
import { useEffect } from "react";
import { SocketContext } from "../Socket/Socket";
// import MapView from './MapView';


const { Option } = Select;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function UnloadingForm() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const socket = useContext(SocketContext)

  var isLoggedIn = localStorage.getItem("loggedIn");

  var data = null;
  if (isLoggedIn) {
    // eslint-disable-next-line no-unused-vars
    data = JSON.parse(localStorage.getItem("adminInfo"));
  } else {
    localStorage.clear();
    dispatch({ type: ActionTypes.ADMIN_LOGOUT });
    navigate("/login");
  }

  const [form] = Form.useForm();
  const params = useParams();

  const [tripInfo,setTripInfo] = useState({})
  const [weighBrideFile, setWeighBrideFile] = useState("");
  const [mrSlip, setMRSlip] = useState("");

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [loading, setLoading] = useState(false);

  const [checkValue, setCheckValue] = useState("");

  const onFinish = (values) => {
    setLoading(true);

    let unloadData = new FormData();

    unloadData.append("unloading_weight", values.unloading_weight.trim());
    unloadData.append("uw_measure", values.uw_measure.trim());
    unloadData.append(
      "accepted_weight",
      values.accepted_weight ? values.accepted_weight.trim() : ""
    );
    unloadData.append(
      "aw_measure",
      values.aw_measure ? values.aw_measure.trim() : ""
    );
    unloadData.append("sId", data._id.trim());
    unloadData.append("remark", values.remark ? values.remark.trim() : "");
    unloadData.append("id", params.id.trim());

    if (values.unload_weighbridge) {
      unloadData.append("unload_weighbridge", values.unload_weighbridge.file);
    }

    if (values.mr_slip) {
      unloadData.append("mr_slip", values.mr_slip.file);
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}/admin/unload_trip`, unloadData)
      .then((res) => {
        if (res.data.status) {
          setLoading(false);

          try{
            socket.emit("unload_trip",{
              sId:data._id,
              tripId : params.id
            })
          }
          catch(e){
            console.log(e)
          }

          message.success({content : res.data.message, key:"loading"});
          form.resetFields()
          navigate('/tripdetails')
        } else {
          message.error({content : res.data.message,key:"loading"});
        }
      });
  };

  // HANDLE MODAL PREVIEW

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.preview);
    setPreviewVisible(true);
  };

  // MODAL PREVIEW CANCEL

  const handleCancel = () => setPreviewVisible(false);

  const onUploadWeighBridge = (file) => {
    setWeighBrideFile(file);
  };

  const onUploadMRSlip = (file) => {
    setMRSlip(file);
  };

  useEffect(() => {

    axios.post(`${process.env.REACT_APP_API_URL}/admin/trip_info`,{tripId:params.id}).then(res =>{
      if(res.data.status){
        setTripInfo(res.data.data)
      }
    })
  }, [params.id]);

  useEffect(()=>{
    form.setFieldsValue({
      uw_measure: tripInfo.lw_measure ? tripInfo.lw_measure : "",
      aw_measure: tripInfo.lw_measure ? tripInfo.lw_measure : ""
    })
  })

  return (
        <div className="row ">
                <div className="col-md-6 mt-3">
                <div className="form-card back-white">
                        <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
                        <img alt="example" style={{ width: "100%" }} src={previewImage} />
                        </Modal>
                        <div className="card-heading">
                        <p>Proceed to unload</p>
                        </div>

                        <div className="row">
                        <div className="col-md-12">
                        <Form
                        form={form}
                        name="form"
                        initialValues={{
                                remember: true
                        }}
                        onFinish={onFinish}
                        layout="vertical"
                        autoComplete="off"
                        >
                        <div className="row">
                                <div className="col-md-12">
                                <Form.Item label="Unloading Weight">
                                <Input.Group compact>
                                <Form.Item
                                        name={"unloading_weight"}
                                        noStyle
                                        rules={[
                                        {
                                        pattern: /[0-9]+$/,
                                        message: "Only numeric values or digits allowed!"
                                        },
                                        {
                                        required: true,
                                        message: "Unloading weight is required"
                                        }
                                        ]}
                                >
                                        <Input
                                        style={{ width: "60%" }}
                                        type="number"
                                        placeholder="Enter Unloading Weight"
                                        />
                                </Form.Item>
                                <Form.Item
                                        name={"uw_measure"}
                                        noStyle
                                        rules={[
                                        {
                                        required: true,
                                        message: "Measurement is required"
                                        }
                                        ]}
                                >
                                        <Select
                                        placeholder="Select Measurement Unit" disabled 
                                        style={{ width: "40%" }}
                                        >
                                          <Option value = 'mt'> MT </Option>
                                          <Option value = 'cum'> CUM </Option>
                                          <Option value = 'pcs'> PCS </Option>
                                        </Select>
                                </Form.Item>
                                </Input.Group>
                                </Form.Item>
                                </div>

                                <div className="col-md-12">
                                <Form.Item label="Accepted Weight">
                                <Input.Group compact>
                                <Form.Item
                                        name={"accepted_weight"}
                                        noStyle
                                        rules={[
                                        {
                                        pattern: /[0-9]+$/,
                                        message: "Only numeric values or digits allowed!"
                                        }
                                        ]}
                                >
                                        <Input
                                        style={{ width: "60%" }}
                                        type="number"
                                        placeholder="Enter Accepted Weight"
                                        />
                                </Form.Item>
                                <Form.Item
                                        name={"aw_measure"}
                                        noStyle
                                >
                                        <Select
                                        placeholder="Select Measurement Unit" disabled
                                        style={{ width: "40%" }}
                                        >
                                          <Option value = 'mt'> MT </Option>
                                          <Option value = 'cum'> CUM </Option>
                                          <Option value = 'pcs'> PCS </Option>
                                        </Select>
                                </Form.Item>
                                </Input.Group>
                                </Form.Item>
                                </div>

                                <div className="col-md-6 ">
                                <Form.Item
                                name="unload_weighbridge"
                                label="Weigh Bridge Slip"
                                rules={[
                                {
                                        required: true,
                                        message: "Upload Weighbridge slip"
                                }
                                ]}
                                >
                                <Upload
                                accept="image/*"
                                listType="picture-card"
                                file={weighBrideFile}
                                onPreview={handlePreview}
                                onChange={onUploadWeighBridge}
                                beforeUpload={() => false}
                                >
                                <AiOutlineCamera className="fs-6 me-2" />
                                </Upload>
                                </Form.Item>
                                </div>

                                <div className="col-md-6">
                                <Form.Item name="mr_slip" label="MR Slip">
                                <Upload
                                accept="image/*"
                                listType="picture-card"
                                file={mrSlip}
                                onPreview={handlePreview}
                                onChange={onUploadMRSlip}
                                beforeUpload={() => false}
                                >
                                <AiOutlineCamera className="fs-6 me-2" />
                                </Upload>
                                </Form.Item>
                                </div>

                                <div className="row">
                                <div className="col-md-12">
                                <Form.Item name="confirm_details">
                                <Checkbox
                                        onChange={(e) => setCheckValue(e.target.checked)}
                                >
                                        Check if you are having issues
                                </Checkbox>
                                </Form.Item>
                                </div>

                                {checkValue ? (
                                <div className="col-md-12">
                                <Form.Item
                                        name="remarks"
                                        label="Write Remark"
                                        rules={[
                                        {
                                        required: true,
                                        message: "Please Write your remarks"
                                        }
                                        ]}
                                >
                                        <Input.TextArea
                                        autoSize={{ minRows: 3, maxRows: 5 }}
                                        placeholder="Enter Remarks"
                                        />
                                </Form.Item>
                                </div>
                                ) : (
                                false
                                )}

                                <div className="col-md-12">
                                <Form.Item>
                                <Button htmlType="submit" className="form-btn border-0">
                                        {loading ? <LoadingSpinner /> : "Submit"}
                                </Button>
                                </Form.Item>
                                </div>
                                </div>
                        </div>
                        </Form>
                        </div>
                        </div>
                </div>
                </div>


                {/* <div className="col-md-6 mt-3">
                        <div className="form-card back-white">
                        <MapView/>
                        </div>
                </div> */}
                
        </div>
  );
}

export default UnloadingForm;
