import React from 'react';

function UnauthorizedMessage() {
        return (
                <div className='container'>
                        <div className="row">
                                <div className="col-md-12">
                                        <div className='shadow bg-white p-4 '>
                                                <p className='text-center mb-0 fw-500 font-red'>
                                                Unauthorized Access !
                                                </p>
                                        </div>
                                </div>
                        </div>
                </div>
        );
}

export default UnauthorizedMessage;