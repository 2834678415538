import React,{useState,useEffect} from 'react';
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Input, Select, message, Table, Form,Pagination ,Switch, Button} from "antd";
import { ActionTypes } from '../../redux/constants/ActionTypes';
import LoadingSpinner from './../../components/LoadingSpinner';
import { useContext } from 'react';
import { SocketContext } from '../../Socket/Socket';

const { Option } = Select;

function SendMessage() {

        const dispatch = useDispatch();
        const navigate = useNavigate();

        const socket = useContext(SocketContext)

        const [form] = Form.useForm()
      
        const columns = [
          {
            title: 'S_No',
            key: 'sno',
            width: '20px',
            render: (text, record, index) =>( <span key = {index}>{(page - 1) * 10 + index + 1}</span>)
          
          },
          {
            title: "Name",
            dataIndex: "name",
            key: "name"
          },
          {
            title: "Email",
            dataIndex: "email",
            key: "email"
          },
          {
            title: "Mobile",
            dataIndex: "mobile",
            key: "mobile"
          },
        ];
      
      
        const [sortingOrder,setSortingOrder] = useState("desc")
      
        const[query,setQuery] = useState("")
      
        const [filterValue, setFilterValue] = useState();

        const [selectedRowKeys,setSelectedRowKeys] = useState([]) // Selected Rows
        const [selectedRows,setSelectedRows] = useState([]) // Selected Rows
      
        const [sortBy, setSortBy] = useState("createdAt");

        const [loading,setLoading] = useState(false)

        const [svData, SetSvData] = useState([])
      
        var isLoggedIn = localStorage.getItem("loggedIn");

        
      
        const queryPage = useLocation().search.match(/page=([0-9]+)/, "");
        const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
        const [page, setPage] = useState(currentPage);
        // eslint-disable-next-line no-unused-vars
        const [pageSize, setPageSize] = useState(10);
        const [totalPage, setTotalPage] = useState(1);
      
        const pageChange = (newPage) => {
          if (newPage > 0 && newPage <= totalPage) {
            currentPage !== newPage && navigate(`/userdetails?page=${newPage}`);
            setPage(newPage);
            //       fetchData();
          }
        };
      
        var data = null;
      
        if (isLoggedIn) {
          data = JSON.parse(localStorage.getItem("adminInfo"));
        } else {
          localStorage.clear();
          dispatch({ type: ActionTypes.ADMIN_LOGOUT });
          navigate("/");
        }
      
        // FIRM DELETE
       
      
       
        const handleSorting = (value) =>{
          if(value===true){
            setSortingOrder("asc")
          }else{
            setSortingOrder("desc")
          }
        }
      

        const fetchSupervisor = () =>{
                message.loading({ content: "Loading...", key: "data" });
          axios
            .post(`${process.env.REACT_APP_API_URL}/admin/fetch_supervisor`, {
              id: data._id,
              page: page,
              size: pageSize,
              filterColumn:filterValue,
              query:query,
              sortBy : sortBy,
              sortingOrder :sortingOrder
            })
            .then((res) => {
              if (res.data.status) {
                SetSvData(res.data.data);
                setTotalPage(res.data.totalRows);
                setSelectedRows([])
                setSelectedRowKeys([])
                if(page > 1 && res.data.data.length === 0){
                  navigate(`/userdetails?page=${page-1}`)
                    setPage(page-1)
                }
                message.success({ content: "Loaded", key: "data" });
              } else {
                message.error({ content: res.data.message, key: "data" });
              }
            });
        }

        const onFinish = (values) =>{
          if(selectedRowKeys.length > 0){

            const messageData = {
              title : values.title,
              message : values.message,
              user : selectedRowKeys,
              socketIds: selectedRows,
              messageBy : data._id
            }

            socket.emit("send-message",messageData)

            axios.post(`${process.env.REACT_APP_API_URL}/admin/sendMessage`,messageData).then(res =>{
              if(res.data.status){
                message.success({content : res.data.message,key:"loading"})

                form.resetFields()
                setLoading(false)
              }
              else{
                message.error({content : res.data.message, key:"some"})
                setLoading(false)
              }
            })
          }
          else{
            message.warning({key:"some",content:"Please Select atleast 1 supervisor to send message!"})
          }
        }

        const  onSelectChange = (selectedRowKeys,selectedRows) => {
          
                
                setSelectedRowKeys(selectedRowKeys)     
                
                let ids = selectedRows.map( (item) => item.socket_id ? item.socket_id : "");

                setSelectedRows(ids)

              };

            

        const rowSelection = {
                selectedRowKeys,
                onChange: onSelectChange,
              };
      
        useEffect(() => {
                fetchSupervisor();
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [page,filterValue,query,sortBy,sortingOrder]);	
        // const onShowSizeChange = (current, pageSize) => {
        //   console.log()
        // };
      


        useEffect(()=>{
          socket.on("update",()=>{
           fetchSupervisor()
          })
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[socket])
      


	


        return (
                <div className='container'>

                        <div className="row">
                                {/* Message Form */}
                                <div className="col-md-4 mt-3">

                                <div className="form-card bg-white">
                                        <div className="card-heading">
                                        <p>Message</p>
                                        </div>
                                        <div>
                                        <Form
                                                form={form}
                                                name="form"
                                                initialValues={{
                                                remember: true
                                                }}
                                                onFinish={onFinish}
                                                layout="vertical"
                                                autoComplete="off"
                                        >
                                                <div className="row">
                                                
                                                <div className="col-md-12 ">
                                                <Form.Item
                                                label="Title"
                                                name="title"
                                                rules={[
                                                        {
                                                        pattern:/^[a-zA-Z_ ]+$/,
                                                        message : "Title must contain alpha characters only!"
                                                        },
                                                        {
                                                        required: true,
                                                        message: "Please input message title!"
                                                        }
                                                ]}
                                                >
                                                <Input placeholder="Enter Title" />
                                                </Form.Item>
                                                </div>
                                                </div>

                                        
                                        
                                        
                                        
                                                <div className="col-md-12 ">
                                                <Form.Item
                                                label="Message"
                                                name="message"
                                                rules={[
                                                        {
                                                        pattern:/^[a-zA-Z0-9_ , ]+$/,
                                                        message : "message can contain alphanumeric characters only!"
                                                        },
                                                        {
                                                        required: true,
                                                        message: "Please input message title!"
                                                        }
                                                ]}
                                                >
                                                <Input.TextArea autoSize={{ minRows: 3, maxRows: 3 }} placeholder="Write Message... " />
                                                </Form.Item>
                                                </div>

                                                <div className="col-md-6">
                                                <Form.Item>
                                                <Button htmlType="submit" className="form-btn border-0">
                                                {loading ?<LoadingSpinner/> : "Send"}
                                                </Button>
                                                </Form.Item>
                                                </div>
                                        </Form>
                                        </div>
                                        </div>

                                </div>



                                {/* TABLE */}
                                <div className="col-md-8 mt-3">
                                        <div className="form-card h-100 back-white">
                                                <div className="card-heading">
                                                        <p>Supervisor</p>
                                                </div>


                                        <div className="row my-3">
                                                <div className="col-md-3">
                                                <label className ="custom-label">Sort By :</label>
                                                <Select
                                                onChange={(value) => setSortBy(value)}
                                                placeholder ="Sort By:"
                                                style={{ width: "100%" }} 
                                                value ={sortBy}
                                                >
                                                <Option value ="createdAt">Default</Option>
                                                <Option value="name">Name</Option>
                                                </Select>
                                                </div>
                                                <div className="col-md-3 ">
                                                <label className ="custom-label">Sorting order :</label>
                                                <Switch
                                                        checkedChildren={sortingOrder}
                                                        unCheckedChildren={sortingOrder}
                                                        className="d-block"
                                                        // defaultChecked
                                                        onChange={handleSorting}/>
                                                        
                                                </div>
                                                <div className="col-md-3">
                                                <label className="custom-label">filter By:</label>
                                                <Select
                                                onChange={(value) => setFilterValue(value)}
                                                placeholder ="Filter By:"
                                                style={{ width: "100%" }} 
                                                value ={filterValue}
                                                >
                                                <Option value="name">Name</Option>
                                                <Option value="email">Email</Option>
                                                <Option value="mobile">Mobile</Option>
                                                </Select>
                                                </div>
                                                <div className="col-md-3">
                                                <label className="custom-label">Type Query :</label>
                                                <Input type="text" placeholder="Type Query..." onChange ={(e)=>setQuery(e.target.value)}/>
                                                </div>
                                        </div>
                                        <div className="row">
                                                <div className="col-md-12">
                                                <Table
                                                rowKey="_id"
                                                className="bg-white"
                                                columns={columns}
                                                rowSelection={rowSelection}
                                                dataSource={svData}
                                                pagination={false}
                                                size = "small"
                                                style={{ overflow: "auto" }}
                                                />
                                                </div>
                                                <div className="col-md-12">
                                                <Pagination
                                                className="bg-white p-2 mt-3 text-center"
                                                showSizeChanger = {false}
                                                onChange={pageChange}
                                                defaultCurrent={1}
                                                total={totalPage}
                                                />
                                                </div>
                                        </div>


                                        </div>
                                </div>
                        </div>
                </div>
        );
}

export default SendMessage;