import React , {useEffect , useState} from 'react';
import {BsTruckFlatbed,BsTruck} from "react-icons/bs"
import {ImTruck} from "react-icons/im"
import {HiTruck} from "react-icons/hi"
import {GiRoad} from "react-icons/gi"
import {FaTruckLoading} from "react-icons/fa"
import axios from "axios";
import { message } from 'antd';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { ActionTypes } from '../../redux/constants/ActionTypes';
import TripDashInfo from './../../components/TripDashInfo';
import SupDashCard from './../../components/SupDashCard';

function SupervisorDashboard() {


        const dispatch = useDispatch()
        const navigate = useNavigate()

        var isLoggedIn = localStorage.getItem("loggedIn")
  
        var data = null
        if(isLoggedIn)
        {
          data = JSON.parse(localStorage.getItem("adminInfo")) 
        }
        else
        {
          localStorage.clear();
          dispatch({ type: ActionTypes.ADMIN_LOGOUT });
          navigate("/");
        }


     const[svData,setSvData] = useState({})
     const[tripInfo,setTripInfo] = useState([])
   


      // FETCH SUPERVISOR DATA

      const fetchData = () =>{

                message.loading({content:"Loading.." , key : "loading"})

                axios.post(`${process.env.REACT_APP_API_URL}/admin/sv_dash_data`,{id:data._id,role:data.role}).then(res => {

                        if(res.data.status){

                                setSvData(res.data.data)
                                setTripInfo(res.data.data.onWayTripInfo)
                                message.success({content :"Data Loaded", key : "loading"})
                        }

                        else
                        {
                                message.error({content : res.data.message , key : "loading"})
                        }

                })
      }

     

        useEffect(()=>{

                //SUPERVISOR DATA
             
                fetchData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[])




        return (
                <div className='container-fluid'>
                       
                        <div className="row">
                                <div className="col-md-6 col-lg-3 my-2">
                                        {/* TRUCKS ADD BY SUPERVISOR */}

                                       <SupDashCard icon = {<BsTruckFlatbed/>} totalCount = {svData.totalWaitingTruck ? svData.totalWaitingTruck : 0} infoTitle = {"Waiting For Loading "} supCount = {svData.waitingTruck ? svData.waitingTruck : 0} iconBg = {"card-gradiant-orange"}
                                       linkPath = {"/addtruck"} linkTitle = {"view more"}/>
                                </div>
                                <div className="col-md-6 col-lg-3 my-2">
                                        {/* TRUCKS LOADED BY SUPERVISOR */}

                                       <SupDashCard icon = {<ImTruck/>}  totalCount = {svData.totalLoadedTruck ? svData.totalLoadedTruck : 0} supCount = {svData.loadedTruck ? svData.loadedTruck : 0} infoTitle = {"Loaded Truck"} iconBg = {"card-gradiant-green"} linkPath = {"/truckdetails"} linkTitle = {"view more"}/>
                                </div>
                                <div className="col-md-6 col-lg-3 my-2">
                                        {/* TRUCKS ADD BY SUPERVISOR */}

                                       <SupDashCard icon = {<BsTruck/>}  totalCount = {svData.totalCanceledTruck ? svData.totalCanceledTruck : 0} supCount = {svData.cancelTruck ? svData.cancelTruck : 0} infoTitle = {"Cancel Truck"} iconBg = {"card-gradiant-red"} linkPath = {"/truckdetails"} linkTitle = {"view more"}/>
                                </div>
                                <div className="col-md-6 col-lg-3 my-2">
                                        {/* TRIPS LOADED BY SUPERVISOR */}

                                       <SupDashCard icon = {<HiTruck/>} totalCount = {svData.totalLoadedTrips ? svData.totalLoadedTrips : 0} supCount = {svData.loadedTrips ? svData.loadedTrips : 0} infoTitle = {"Ready Trips"} iconBg = {"card-gradiant-info"} linkPath = {"/readytrips"} linkTitle = {"view more"} />
                                </div>
                        </div>

                        <div className="row">
                               
                                <div className="col-md-6 col-lg-3 my-2">
                                        {/* TRIPS LOADED BY SUPERVISOR */}

                                       <SupDashCard icon = {<GiRoad/>} totalCount = {svData.totalDispatchTrips ? svData.totalDispatchTrips : 0} supCount = {svData.dispatchTrips ? svData.dispatchTrips : 0} infoTitle = {"On The Way Trips"} iconBg = {"card-gradiant-orange"} linkPath = {"/onwaytrips"} linkTitle = {"view more"}/>
                                </div>
                                <div className="col-md-6 col-lg-3 my-2">
                                        {/* TRIPS ARRIVED BY SUPERVISOR */}

                                       <SupDashCard icon = {<ImTruck/>} totalCount = {svData.totalArrivedTrips ? svData.totalArrivedTrips : 0} supCount = {svData.arrivedTrips ? svData.arrivedTrips : 0} infoTitle = {"Arrived Trips"} iconBg = {"card-gradiant-info"} linkPath = {"/arrivedtrips"} linkTitle = {"view more"} />
                                </div>
                                <div className="col-md-6 col-lg-3 my-2">
                                        {/* TRIPS LOADED BY SUPERVISOR */}
                                                {
                                                        data.role === "supervisor" ? 
                                                                <SupDashCard icon = {<FaTruckLoading/>} info = {true} supCount = {svData.unloadedTrips ? svData.unloadedTrips : 0} infoTitle = {"Complete Trips"} iconBg = {"card-gradiant-green"} linkPath = {"/tripdetails"} linkTitle = {"view more"} />:

                                                                <SupDashCard icon = {<FaTruckLoading/>} info = {true} supCount = {svData.totalUnloadedTrips ? svData.totalUnloadedTrips : 0} infoTitle = {"Complete Trips"} iconBg = {"card-gradiant-green"} linkPath = {"/tripdetails"} linkTitle = {"view more"} />
                                                }
                                       
                                </div>
                                <div className="col-md-6 col-lg-3 my-2">
                                        {/* TRIPS CANCEL BY SUPERVISOR */}

                                       <SupDashCard icon = {<BsTruck/>} totalCount = {svData.totalCanceledTrips ? svData.totalCanceledTrips : 0} supCount = {svData.cancelTrips ? svData.cancelTrips : 0} infoTitle = {"Cancel Trips"} iconBg = {"card-gradiant-red"} linkPath = {"/tripdetails"} linkTitle = {"view more"}/>
                                </div>
                                <div className="col-md-6 col-lg-3 my-2">
                                {/* REDIRECTED TRIPS BY SUPERVISOR */}

                                {
                                        data.role === "supervisor" ?
                                        <SupDashCard icon = {<HiTruck/>} info = {true} supCount = {data.role === "supervisor " ?svData.redirectedTrips ? svData.redirectedTrips : 0 : svData.redirectedTrips ? svData.redirectedTrips:0} infoTitle = {"Redirected Trips"} iconBg = {"card-gradiant-info"} linkPath = {"/tripdetails"} linkTitle = {"view more"} />
                                        : 
                                        <SupDashCard icon = {<HiTruck/>} info = {true} supCount = {data.role === "supervisor " ?svData.totalRedirectedTrips ? svData.totalRedirectedTrips : 0 : svData.totalRedirectedTrips ? svData.totalRedirectedTrips:0} infoTitle = {"Redirected Trips"} iconBg = {"card-gradiant-info"} linkPath = {"/tripdetails"} linkTitle = {"view more"} />
                                }
                               
                                </div>
                              

                        </div>
                       
                        <div className="row">
                        <div className="col-md-12">
                                <div className='form-card back-white mt-4 pb-4'>
                                        <div className="dash-trip-heading card-gradiant-theme d-flex align-items-center justify-content-between">
                                                <p className='d-inline'>On The Way Trips </p>
                                                <Link to = "/arrivedtrips" className='btn text-white fw-500 outline-white'>Arrived Trips</Link>
                                        </div>

                                        <div className='row'>

                                                {
                                                          tripInfo.length > 0 ?
                                                        tripInfo.map((element) =>{
                                                                return <TripDashInfo key ={element._id}
                                                                        firm = {element.firm ? element.firm[0] ? element.firm[0] :"":""}
                                                                  truck = {element.truckInfo?element.truckInfo[0]?element.truckInfo[0]:"":"" }
                                                                  trip = {element.trip ? element.trip : ""}
                                                                  />
                                                                
                                                        })
                                                          :
                                                        <div className="row">
                                                                <div className="col-md-4 mx-auto form-card back-white trip-card">
                                                                        <p className='text-center'>No Trips Available </p>
                                                                </div>        
                                                        </div>
                                                }
                                               
                                        </div>


                                </div>
                        </div>
                       
                        </div>

                     
                </div>
        );
}

export default SupervisorDashboard;