import React,{useState,useEffect} from "react";
import { Form, Input, Button,message,Select } from "antd";
import axios from "axios";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ActionTypes } from './../../../redux/constants/ActionTypes';
import { useParams } from 'react-router-dom';

const {Option} =  Select;

function EditTruck() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()


  const [form] = Form.useForm();
  const [loading,setLoading] = useState(false)
  const [truckDetails , setTruckDetails] = useState([])


  var isLoggedIn = localStorage.getItem("loggedIn")
  
  var data = null
  if(isLoggedIn)
  {
    data = JSON.parse(localStorage.getItem("adminInfo"))
    
  }
  else
  {
    localStorage.clear();
    dispatch({ type: ActionTypes.ADMIN_LOGOUT });
    navigate("/");
  }

 const fetchTruckDetails = () =>{
         axios.post(`${process.env.REACT_APP_API_URL}/admin/fetch_truck_id`,{id:params.id}).then((res) =>{
               if(res.data.status){
                      setTruckDetails(res.data.data) 
               }  
               else{
                       message.success({key:"loading",content:res.data.message})
               }
         })
 }

        useEffect(()=>{
                fetchTruckDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[])

        useEffect(()=>{

                form.setFieldsValue({
                        trans_name:truckDetails.transporter_name?truckDetails.transporter_name:null,
                        trans_mobile:truckDetails.transporter_number?truckDetails.transporter_number:null,
                        truck_no:truckDetails.truck_number ? truckDetails.truck_number :null,
                        driver_name:truckDetails.driver_name ? truckDetails.driver_name : null,
                        driver_no:truckDetails.driver_number ? truckDetails.driver_number : null,
                        truck_type:truckDetails.truck_type ? truckDetails.truck_type : null
                })

        },[truckDetails,form])

 
 

  const onFinish = (values) => {
   
      setLoading(true)
      const truckData = {
        trans_company_name : values.trans_com_name?values.trans_com_name.trim():"",
        trans_company_number : values.trans_com_mobile?values.trans_com_mobile.trim():"",
        truck_number : values.truck_no?values.truck_no.trim() : "",
        driver_name : values.driver_name?values.driver_name.trim():"",
        driver_number : values.driver_no?values.driver_no.trim():"",
        truck_type : values.truck_type?values.truck_type.trim():"",
        id:params.id.trim(),//TRUCK ID
        sid:data._id.trim()//SUPERVISOR ID
      };

      try{
        axios
        .post(`${process.env.REACT_APP_API_URL}/admin/edit_truck`, truckData)
        .then((res) => {
          if (res.data.status) {
            message.success({ content: res.data.message, key: "loading" });
            setLoading(false)
            // alert(res.data.message)
          } else {
            message.error({ content: res.data.message, key: "loading" });
            setLoading(false)
          }
        });
      }catch(e){
        alert("Server Error!")
      }
    
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="form-card bg-white">
            <div className="card-heading">
              <p>Edit Truck</p>
            </div>
            <div>
              <Form
                form={form}
                name="form"
                initialValues={{
                  remember: true
                }}
                onFinish={onFinish}
                layout="vertical"
                autoComplete="off"
              >
                <div className="row">

                 {/* TRANSPORT COMPANY NAME */}
                  <div className="col-md-6">
                    <Form.Item
                      label="Transporter Name"
                      name="trans_name"
                      rules={[
                        {
                          pattern:/^[a-zA-Z_ ]+$/,
                          message : "Transporter name must contain alpha characters only!"
                        },
                        {
                          required: true,
                          message: "Please input transporter name!"
                        }
                      ]}
                    >
                      <Input placeholder="Enter transporter name " />
                    </Form.Item>
                  </div>


                  {/* TRANSPORT COMPANY MOBILE */}
                  <div className="col-md-6">
                    <Form.Item
                      label="Transporter mobile"
                      name="trans_mobile"
                      rules={[
                        {
                          pattern: /^[0-9]{10}$/,
                          message: "Input valid mobile number!"
                        },
                        // {
                        //   required: true,
                        //   message: "Please input transporter mobile number!"
                        //   // whitespace:true,
                        // }
                      ]}
                    >
                      <Input type="number" placeholder="Enter transporter mobile" />
                    </Form.Item>
                  </div>
                </div>

               
                <div className="row">

                 {/* TRUCK NUMBER */}

                  <div className="col-md-6 ">
                    <Form.Item
                      label="Truck No"
                      name="truck_no"
                      rules={[
                        {
                          pattern:/^[A-Z0-9_ ]+$/,
                          message : "Truck Number can contain alpha numeric characters only!"
                        },
                        {
                          required: true,
                          message: "Please input your truck no!"
                        }
                      ]}
                    >
                      <Input placeholder="Enter truck no " onInput={(e) => e.target.value = ( e.target.value).toUpperCase()}/>
                    </Form.Item>
                  </div>

                  {/*  DRIVER NAME */}



                  <div className="col-md-6 ">
                    <Form.Item
                      label="Driver Name"
                      name="driver_name"
                      rules={[
                        {
                          pattern:/^[a-zA-Z_ ]+$/,
                          message : "Driver name must contain alpha characters only"
                        }
                      ]}
                    >
                      <Input placeholder="Enter driver name "/>
                    </Form.Item>
                  </div>


                  {/*  DRIVER MOBILE NUMBER */}


                  <div className="col-md-6 ">
                    <Form.Item
                      label="Driver Mobile no"
                      name="driver_no"
                      rules={[
                        {
                          pattern: /^[0-9]{10}$/,
                          message: "Input valid mobile number!"
                        }
                      ]}
                    >
                      <Input type="number" placeholder="Enter driver mobile no "/>
                    </Form.Item>
                  </div>
                  <div className="col-md-6">

                    <Form.Item
                        label = "Truck Type"
                        name="truck_type"
                        rules={[{ required: true, message: 'Truck type is required!' }]}
                      >
                        <Select placeholder = "Select Truck Type" style={{width : '100%'}}>

                          <Option value = 'jack'>Jack </Option>
                          <Option value = 'manual'> Manual </Option>
                      
                        </Select>
                      </Form.Item>

                    </div>
                </div>
                <div className="col-md-6">
                  <Form.Item>
                    <Button htmlType="submit" className="form-btn border-0">
                     {loading?<LoadingSpinner/>:"Submit"} 
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditTruck;
