import React, { useState } from "react";
import {
  Form,
  Input,
  Upload,
  Modal,
  Button,
  message,
  Select,
  Image,
} from "antd";
import { AiOutlineCamera } from "react-icons/ai";
import LoadingSpinner from "./LoadingSpinner";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ActionTypes } from "./../redux/constants/ActionTypes";
import axios from "axios";
import { useEffect } from "react";
import moment from "moment";
// import MapView from './MapView';

const { Option } = Select;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function UnloadInfoForm(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  var isLoggedIn = localStorage.getItem("loggedIn");

  var data = null;
  if (isLoggedIn) {
    // eslint-disable-next-line no-unused-vars
    data = JSON.parse(localStorage.getItem("adminInfo"));
  } else {
    localStorage.clear();
    dispatch({ type: ActionTypes.ADMIN_LOGOUT });
    navigate("/login");
  }

  const [form] = Form.useForm();
  const params = useParams();

  const [mrSlip, setMRSlip] = useState("");

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);

    let unloadData = new FormData();

    unloadData.append(
      "accepted_weight",
      values.accepted_weight ? values.accepted_weight.trim() : ""
    );
    unloadData.append(
      "aw_measure",
      values.aw_measure ? values.aw_measure.trim() : ""
    );
    unloadData.append("sId", data._id.trim());
    unloadData.append("id", params.id.trim());

    if (values.mr_slip) {
      unloadData.append("mr_slip", values.mr_slip.file);
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/admin/unload_trip_form`,
        unloadData
      )
      .then((res) => {
        if (res.data.status) {
          setLoading(false);
          message.success({ content: res.data.message, key: "loading" });
          props.infoCall();
          form.resetFields();
          //   navigate('/tripdetails')
        } else {
          message.error({ content: res.data.message, key: "loading" });
        }
      });
  };

  // HANDLE MODAL PREVIEW

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.preview);
    setPreviewVisible(true);
  };

  // MODAL PREVIEW CANCEL

  const handleCancel = () => setPreviewVisible(false);

  const onUploadMRSlip = (file) => {
    setMRSlip(file);
  };

  useEffect(() => {
    form.setFieldsValue({
      aw_measure: props.tripData.lw_measure ? props.tripData.lw_measure : "",
    });
  });

  return (
    <div className="row ">
      <div className="col-md-6 mt-3">
        <div className="form-card h-100 back-white">
          <div className="card-heading">
            <p>Unloading Details</p>
          </div>

          <div>
            <div className="row">
              <div className="col-6">
                <p className="fw-500">Unloading Weight</p>
              </div>

              <div className="col-6">
                <p className="text-uppercase">
                  {props.tripData.unloading_weight && props.tripData.uw_measure
                    ? props.tripData.unloading_weight +
                      " " +
                      props.tripData.uw_measure
                    : ""}
                </p>
              </div>
            </div>
            {props.tripData.accepted_weight && props.tripData.aw_measure ? (
              <div className="row ">
                <div className="col-6">
                  <p className="fw-500">Accepted Weight</p>
                </div>

                <div className="col-6">
                  <p className="text-uppercase">
                    {props.tripData.accepted_weight +
                      " " +
                      props.tripData.aw_measure}
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="row">
              <div className="col-6">
                <p className="fw-500">Unloading By</p>
              </div>

              <div className="col-6">
                <p className="text-capitalize">
                  {" "}
                  {props.tripData.unloaded_by
                    ? props.tripData.unloaded_by[0]
                      ? props.tripData.unloaded_by[0]
                      : ""
                    : ""}{" "}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <p className="fw-500">Unloading Time</p>
              </div>

              <div className="col-6">
                <p className="text-capitalize">
                  {props.tripData.unloading_time
                    ? moment(props.tripData.unloading_time).format(
                        "DD-MM-YYYY hh:mm:ss"
                      )
                    : ""}
                </p>
              </div>
            </div>

            <div className="row">
              {props.tripData.unload_weighbridge ? (
                <div className="col-md-6">
                  <p className="fw-500">Unloading Weighbridge</p>
                  <Image
                    src={
                      process.env.REACT_APP_API_URL +
                      "/" +
                      props.tripData.unload_weighbridge
                    }
                    width={80}
                    height={80}
                  />
                </div>
              ) : (
                false
              )}

              {props.tripData.mr_slip ? (
                <div className="col-md-6">
                  <p className="fw-500">MR Slip</p>
                  <Image
                    src={
                      process.env.REACT_APP_API_URL +
                      "/" +
                      props.tripData.mr_slip
                    }
                    width={80}
                    height={80}
                  />
                </div>
              ) : (
                false
              )}
            </div>
          </div>
        </div>
      </div>

      {!props.tripData.accepted_weight || !props.tripData.mr_slip ? (
        <div className="col-md-6 mt-3">
          <div className="form-card h-100 back-white">
            <Modal
              visible={previewVisible}
              footer={null}
              onCancel={handleCancel}
            >
              <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
            <div className="card-heading">
              <p>Unload Info</p>
            </div>

            <div className="row">
              <div className="col-md-12">
                <Form
                  form={form}
                  name="form"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                  layout="vertical"
                  autoComplete="off"
                >
                  <div className="row">
                    {!props.tripData.accepted_weight ? (
                      <div className="col-md-12">
                        <Form.Item label="Accepted Weight">
                          <Input.Group compact>
                            <Form.Item
                              name={"accepted_weight"}
                              noStyle
                              rules={[
                                {
                                  pattern: /[0-9]+$/,
                                  message:
                                    "Only numeric values or digits allowed!",
                                },
                              ]}
                            >
                              <Input
                                style={{ width: "60%" }}
                                type="number"
                                placeholder="Enter Accepted Weight"
                              />
                            </Form.Item>
                            <Form.Item name={"aw_measure"} noStyle>
                              <Select
                                placeholder="Select Measurement Unit"
                                disabled
                                style={{ width: "40%" }}
                              >
                                <Option value="mt"> MT </Option>
                                <Option value="cum"> CUM </Option>
                                <Option value="pcs"> PCS </Option>
                              </Select>
                            </Form.Item>
                          </Input.Group>
                        </Form.Item>
                      </div>
                    ) : (
                      ""
                    )}

                    {!props.tripData.mr_slip ? (
                      <div className="col-md-12">
                        <Form.Item name="mr_slip" label="MR Slip">
                          <Upload
                            accept="image/*"
                            listType="picture-card"
                            file={mrSlip}
                            onPreview={handlePreview}
                            onChange={onUploadMRSlip}
                            beforeUpload={() => false}
                          >
                            <AiOutlineCamera className="fs-6 me-2" />
                          </Upload>
                        </Form.Item>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="col-md-12">
                      <Form.Item>
                        <Button htmlType="submit" className="form-btn border-0">
                          {loading ? <LoadingSpinner /> : "Submit"}
                        </Button>
                      </Form.Item>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        " "
      )}
    </div>
  );
}

export default UnloadInfoForm;
