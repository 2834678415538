import React from 'react';
import {HiOutlineTruck,HiLocationMarker} from "react-icons/hi"



function TripDashInfo(props) {

       
        var matches = props.firm.match(/\b(\w)/g); // ['J','S','O','N']
        var firmacronym = matches.join(''); // JSON
        return (
                <div className="col-md-3 my-3">
                        <div className="form-card back-white trip-card">

                                <div className="trip-card-top">
                                      <p className='text-uppercase'>{firmacronym}</p> 
                                </div>

                                <div className="trip-card-body">
                                        <p className='text-capitalize'> <span><HiOutlineTruck className='me-2'/> </span>{props.firm}</p>
                                        <p>Truck Number : {props.truck}</p>
                                        <p><span><HiLocationMarker className='font-red me-2'/></span>Route : {props.trip}</p>
                                </div>

                        </div>
                </div>
            
        );
}

export default TripDashInfo;