import React, { useEffect, useState } from "react";
import axios from "axios";
import { message } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ActionTypes } from "./../../../redux/constants/ActionTypes";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Image } from "antd";
import UnloadingForm from "../../../components/UnloadingForm";
import { useLocation } from "react-router-dom";
import CancelTrip from "./CancelTrip";
import UnloadInfoForm from "./../../../components/UnloadInfoForm";

function UnloadTrip() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  var proceedStatus = searchParams.get("status");

  const [arrivalInfo, setArrivalInfo] = useState([]);
  const [redirectInfo, setRedirectInfo] = useState([]);

  var data = [];

  const loggedIn = localStorage.getItem("loggedIn");

  if (loggedIn) {
    // eslint-disable-next-line no-unused-vars
    data = JSON.parse(localStorage.getItem("adminInfo"));
  } else {
    localStorage.clear();
    dispatch({ type: ActionTypes.ADMIN_LOGOUT });
    navigate("/login");
  }

  const fetchRedirectInfo = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/admin/redirectinfo`, {
        id: params.id,
      })
      .then((res) => {
        if (res.data.status) {
          setRedirectInfo(res.data.data[0]);
        } else {
          message.error({key:"loading",content:res.data.message});
        }
      });
  };

  const fetchInfo = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/admin/arrived_trip_data`, {
        id: params.id,
      })
      .then((res) => {
        if (res.data.status) {
          setArrivalInfo(res.data.data[0]);
          if (res.data.data[0].redirected) {
            fetchRedirectInfo();
          }
        } else {
          message.error({key:"loading",content:res.data.message});
        }
      });
  };

  useEffect(() => {
    fetchInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-4 mt-3">
          <div className="form-card h-100 back-white">
            <div className="card-heading">
              <p>Truck Details</p>
            </div>

            <div>
              <div className="row">
                <div className="col-6">
                  <p className="fw-500">Truck Number</p>
                </div>

                <div className="col-6">
                  <p className="text-capitalize">
                    {arrivalInfo.truckNumber
                      ? arrivalInfo.truckNumber[0]
                        ? arrivalInfo.truckNumber[0]
                        : ""
                      : ""}
                  </p>
                </div>
              </div>
              <div className="row ">
                <div className="col-6">
                  <p className="fw-500">Transporter Name</p>
                </div>

                <div className="col-6">
                  <p className="text-capitalize">
                    {arrivalInfo.truckTransName
                      ? arrivalInfo.truckTransName[0]
                        ? arrivalInfo.truckTransName[0]
                        : ""
                      : ""}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <p className="fw-500">Transpoter Mobile</p>
                </div>

                <div className="col-6">
                  <p className="text-capitalize">
                    {arrivalInfo.truckTransNo
                      ? arrivalInfo.truckTransNo[0]
                        ? arrivalInfo.truckTransNo[0]
                        : ""
                      : ""}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <p className="fw-500">Driver Name</p>
                </div>

                <div className="col-6">
                  <p className="text-capitalize">
                    {arrivalInfo.truckDriverName
                      ? arrivalInfo.truckDriverName[0]
                        ? arrivalInfo.truckDriverName[0]
                        : ""
                      : ""}
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <p className="fw-500">Driver Mobile</p>
                </div>

                <div className="col-6">
                  <p className="text-capitalize">
                    {arrivalInfo.driver_mobile
                      ? arrivalInfo.driver_mobile[0]
                        ? arrivalInfo.driver_mobile[0]
                        : ""
                      : ""}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-8 mt-3 ">
          <div className="form-card h-100 back-white">
            <div className="card-heading d-flex justify-content-between">
              <p>Trip Details</p>

              {arrivalInfo.status === "complete" ? (
                <span className="fs-6 fw-500 ">
                  Difference in Weight{" "}
                  <span className="text-danger text-uppercase">
                    {arrivalInfo.loading_weight && arrivalInfo.unloading_weight
                      ? parseFloat(
                          arrivalInfo.loading_weight -
                            arrivalInfo.unloading_weight
                        ).toFixed(2) +
                        " " +
                        arrivalInfo.lw_measure
                      : ""}
                  </span>{" "}
                </span>
              ) : (
                ""
              )}
            </div>

            <div className="arrival-card-info row pb-3">
              <div className="row">
                <div className="col-md-6 d-flex">
                  <div className="col-6">
                    <p className="fw-500">Firm Name</p>
                  </div>

                  <div className="col-6">
                    <p className="text-capitalize">
                      {arrivalInfo.firm
                        ? arrivalInfo.firm[0]
                          ? arrivalInfo.firm[0]
                          : ""
                        : ""}
                    </p>
                  </div>
                </div>

                <div className="col-md-6 d-flex">
                  <div className="col-6">
                    <p className="fw-500">Supplier Name</p>
                  </div>

                  <div className="col-6">
                    <p className="text-capitalize">
                      {arrivalInfo.supplier ? arrivalInfo.supplier : ""}
                    </p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 d-flex">
                  <div className="col-6">
                    <p className="fw-500">Trip/Route</p>
                  </div>

                  <div className="col-6">
                    <p className="text-capitalize">
                      {arrivalInfo.trip ? arrivalInfo.trip : ""}
                    </p>
                  </div>
                </div>

                <div className="col-md-6 d-flex">
                  <div className="col-6">
                    <p className="fw-500">Loading Weight</p>
                  </div>

                  <div className="col-6">
                    <p className="text-capitalize">
                      {arrivalInfo.loading_weight && arrivalInfo.lw_measure
                        ? arrivalInfo.loading_weight +
                          " " +
                          arrivalInfo.lw_measure
                        : ""}
                    </p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 d-flex">
                  <div className="col-6">
                    <p className="fw-500">Material Details</p>
                  </div>

                  <div className="col-6">
                    <p className="text-capitalize">
                      {arrivalInfo.material_details
                        ? arrivalInfo.material_details
                        : ""}
                    </p>
                  </div>
                </div>

                <div className="col-md-6 d-flex">
                  <div className="col-6">
                    <p className="fw-500">Material Type</p>
                  </div>

                  <div className="col-6">
                    <p className="text-capitalize">
                      {arrivalInfo.material_type
                        ? arrivalInfo.material_type
                        : ""}
                    </p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 d-flex">
                  <div className="col-6">
                    <p className="fw-500">MR Number</p>
                  </div>

                  <div className="col-6">
                    <p className="text-capitalize">
                      {arrivalInfo.mr_no ? arrivalInfo.mr_no : ""}
                    </p>
                  </div>
                </div>
                <div className="col-md-6 d-flex">
                  <div className="col-6">
                    <p className="fw-500">Invoice Number</p>
                  </div>

                  <div className="col-6">
                    <p className="text-capitalize">
                      {arrivalInfo.invoice_no ? arrivalInfo.invoice_no : ""}
                    </p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 d-flex">
                  <div className="col-6">
                    <p className="fw-500">TP Number</p>
                  </div>

                  <div className="col-6">
                    <p className="text-capitalize">
                      {arrivalInfo.tp_no ? arrivalInfo.tp_no : ""}
                    </p>
                  </div>
                </div>
                <div className="col-md-6 d-flex">
                  <div className="col-6">
                    <p className="fw-500">TP Weight</p>
                  </div>

                  <div className="col-6">
                    <p className="text-capitalize">
                      {arrivalInfo.tp_weight && arrivalInfo.tw_measure
                        ? arrivalInfo.tp_weight + " " + arrivalInfo.tw_measure
                        : " "}
                    </p>
                  </div>
                </div>
              </div>

              <div className="row border-top pt-3">
                {arrivalInfo.invoice_file ? (
                  <div className="col-md-4">
                    <p className="fw-500">Invoice File</p>
                    <Image
                      src={
                        process.env.REACT_APP_API_URL +
                        "/" +
                        arrivalInfo.invoice_file
                      }
                      width={80}
                      height={80}
                    />
                  </div>
                ) : (
                  false
                )}

                {arrivalInfo.weight_bridge_slip ? (
                  <div className="col-md-4">
                    <p className="fw-500">Weight Bridge Slip</p>
                    <Image
                      src={
                        process.env.REACT_APP_API_URL +
                        "/" +
                        arrivalInfo.weight_bridge_slip
                      }
                      width={80}
                      height={80}
                    />
                  </div>
                ) : (
                  false
                )}
                {arrivalInfo.optional_img ? (
                  <div className="col-md-4 mt-2">
                    <p className="fw-500">Optional Picture</p>
                    <Image
                      src={
                        process.env.REACT_APP_API_URL +
                        "/" +
                        arrivalInfo.optional_img
                      }
                      width={80}
                      height={80}
                    />
                  </div>
                ) : (
                  false
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {arrivalInfo.redirected ? (
        <div className="row mt-3">
          <div className="col-md-12">
            <div className="form-card back-white">
              <div className="card-heading">
                <p>Redirect Info</p>
              </div>

              <div className="row">
                <div className="col-md-12 ">
                  <div className="timing-card">
                    <div className="row">
                      <div className="col-5">
                        <p className="fw-500">Redirected From</p>
                      </div>

                      <div className="col-7">
                        <p className="text-capitalize">
                          {redirectInfo.redirected_from
                            ? redirectInfo.redirected_from
                            : ""}
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-5">
                        <p className="fw-500"> Redirected To </p>
                      </div>

                      <div className="col-7">
                        <p>
                          {redirectInfo.redirect_place_after_cancel
                            ? redirectInfo.redirect_place_after_cancel
                            : ""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="fw-500"> Redirect Reason </p>
                      </div>

                      <div className="col-7">
                        <p>
                          {redirectInfo.on_site_cancel_reason
                            ? redirectInfo.on_site_cancel_reason
                            : ""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="fw-500"> Redirected By</p>
                      </div>

                      <div className="col-7">
                        <p className="text-capitalize">
                          {redirectInfo.redirectedBy
                            ? redirectInfo.redirectedBy[0]
                              ? redirectInfo.redirectedBy[0]
                              : ""
                            : ""}
                        </p>
                      </div>
                    </div>

                    <div className="row ">
                      <div className="col-5">
                        {" "}
                        <p className="fw-500">Redirect Time</p>
                      </div>

                      <div className="col-7">
                        <p>
                          {redirectInfo.on_site_cancel_time
                            ? moment(redirectInfo.on_site_cancel_time).format(
                                "DD-MM-YYYY hh:mm:ss"
                              )
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="row mt-3">
        <div className="col-md-12">
          <div className="form-card back-white">
            <div className="card-heading">
              <p>Timing</p>
            </div>

            <div className="row">
              <div className="col-md-6 ">
                <div className="timing-card">
                  <div className="row">
                    <div className="col-5">
                      <p className="fw-500">Truck Added By</p>
                    </div>

                    <div className="col-7">
                      <p className="text-capitalize">
                        {arrivalInfo.truckAddBy
                          ? arrivalInfo.truckAddBy[0]
                            ? arrivalInfo.truckAddBy[0]
                            : ""
                          : ""}
                      </p>
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col-5">
                      {" "}
                      <p className="fw-500">Truck Added At</p>
                    </div>

                    <div className="col-7">
                      <p>
                        {arrivalInfo.truckAddedAt
                          ? arrivalInfo.truckAddedAt[0]
                            ? moment(arrivalInfo.truckAddedAt[0]).format(
                                "DD-MM-YYYY hh:mm:ss"
                              )
                            : ""
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 ">
                <div className="timing-card">
                  <div className="row">
                    <div className="col-5">
                      <p className="fw-500">Truck Loaded By</p>
                    </div>

                    <div className="col-7">
                      <p className="text-capitalize">
                        {arrivalInfo.loadedBy
                          ? arrivalInfo.loadedBy[0]
                            ? arrivalInfo.loadedBy[0]
                            : ""
                          : ""}
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-5">
                      <p className="fw-500"> Truck Loading Time</p>
                    </div>

                    <div className="col-7">
                      <p>
                        {arrivalInfo.createdAt
                          ? moment(arrivalInfo.createdAt).format(
                              "DD-MM-YYYY hh:mm:ss"
                            )
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {arrivalInfo.dispatch_time ? (
                <div className="col-md-6">
                  <div className="timing-card">
                    <div className="row">
                      <div className="col-5">
                        <p className="fw-500">Dispatch By</p>
                      </div>

                      <div className="col-7">
                        <p className="text-capitalize">
                          {arrivalInfo.DispatchBy
                            ? arrivalInfo.DispatchBy[0]
                              ? arrivalInfo.DispatchBy[0]
                              : ""
                            : ""}
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-5">
                        <p className="fw-500">Dispatch Time</p>
                      </div>

                      <div className="col-7">
                        <p>
                          {arrivalInfo.dispatch_time
                            ? moment(arrivalInfo.dispatch_time).format(
                                "DD-MM-YYYY hh:mm:ss"
                              )
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {arrivalInfo.arrived_at ? (
                <div className="col-md-6">
                  <div className="timing-card">
                    <div className="row">
                      <div className="col-5">
                        <p className="fw-500">Arrival Marked By</p>
                      </div>

                      <div className="col-7">
                        <p className="text-capitalize">
                          {arrivalInfo.ArrivalInfo
                            ? arrivalInfo.ArrivalInfo[0]
                              ? arrivalInfo.ArrivalInfo[0]
                              : ""
                            : ""}
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-5">
                        <p className="fw-500">Arrival Time</p>
                      </div>

                      <div className="col-7">
                        <p>
                          {arrivalInfo.arrived_at
                            ? moment(arrivalInfo.arrived_at).format(
                                "DD-MM-YYYY hh:mm:ss"
                              )
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {arrivalInfo.cancel_at ? (
                <div className="col-md-6">
                  <div className="timing-card">
                    <div className="row">
                      <div className="col-5">
                        <p className="fw-500">Cancel By</p>
                      </div>

                      <div className="col-7">
                        <p className="text-capitalize">
                          {arrivalInfo.cancelByName
                            ? arrivalInfo.cancelByName[0]
                              ? arrivalInfo.cancelByName[0]
                              : ""
                            : ""}
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-5">
                        <p className="fw-500">Cancel Time</p>
                      </div>

                      <div className="col-7">
                        <p>
                          {arrivalInfo.cancel_at
                            ? moment(arrivalInfo.cancel_at).format(
                                "DD-MM-YYYY hh:mm:ss"
                              )
                            : ""}
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-5">
                        <p className="fw-500">Cancel Reason</p>
                      </div>

                      <div className="col-7">
                        <p className="text-capitalize">
                          {arrivalInfo.cancel_reason
                            ? arrivalInfo.cancel_reason
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {arrivalInfo.unloading_time ? (
                <div className="col-md-6">
                  <div className="timing-card">
                    <div className="row">
                      <div className="col-5">
                        <p className="fw-500">Complete By</p>
                      </div>

                      <div className="col-7">
                        <p className="text-capitalize">
                          {arrivalInfo.unloaded_by
                            ? arrivalInfo.unloaded_by[0]
                              ? arrivalInfo.unloaded_by[0]
                              : ""
                            : ""}
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-5">
                        <p className="fw-500">Complete Time</p>
                      </div>

                      <div className="col-7">
                        <p>
                          {arrivalInfo.unloading_time
                            ? moment(arrivalInfo.unloading_time).format(
                                "DD-MM-YYYY hh:mm:ss"
                              )
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      {data.role === "supervisor" || data.role === "subadmin" ? (
        proceedStatus ? (
          proceedStatus === "proceed" ? (
            <UnloadingForm />
          ) : proceedStatus === "cancel" ? (
            <CancelTrip tripData={arrivalInfo} />
          ) : null
        ) : null
      ) : null}

      {arrivalInfo.status === "complete" ? (
        <UnloadInfoForm tripData={arrivalInfo} infoCall={fetchInfo} />
      ) : (
        ""
      )}
    </div>
  );
}

export default UnloadTrip;
