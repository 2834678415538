import React,{useContext, useState} from "react";
import { Form, Input, Button,message, Select } from "antd";
import axios from "axios";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ActionTypes } from './../../../redux/constants/ActionTypes';
import { waitingTruckDataAction } from "../../../redux/actions/DataAction";
import { useLocation } from 'react-router-dom';
import WaitingTruck from './../../dashboard/WaitingTruck';
import { SocketContext } from "../../../Socket/Socket";

const {Option} = Select;

function AddTruck() {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const socket = useContext(SocketContext)
 

  const queryPage = useLocation().search.match(/page=([0-9]+)/, "");
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const [page, setPage] = useState(currentPage);
  // const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(1);

  // const pageChange = (newPage) => {
  //   if (newPage > 0 && newPage <= totalPage) {
  //     currentPage !== newPage && navigate(`/waitingtruck?page=${newPage}`);
  //     setPage(newPage);
  //     //       fetchData();
  //   }
  // };
  



  const [form] = Form.useForm();
  const [loading,setLoading] = useState(false)


  var isLoggedIn = localStorage.getItem("loggedIn")
  
  var data = null
  if(isLoggedIn)
  {
    data = JSON.parse(localStorage.getItem("adminInfo"))
  }
  else
  {
    localStorage.clear();
    dispatch({ type: ActionTypes.ADMIN_LOGOUT });
    navigate("/");
  }


  // SET PAGE NUMBER
  const some = (page)=>{

    setPage(page)

  }



  const onFinish = (values) => {
   
      setLoading(true)

      const truckData = {
        transporter_name : values.trans_name?values.trans_name.trim() : "",
        transporter_number : values.trans_mobile?values.trans_mobile.trim() : "",
        truck_number :values.truck_no? values.truck_no.trim() : "",
        driver_name : values.driver_name ? values.driver_name.trim() : "",
        driver_number : values.driver_no?values.driver_no.trim():"",
        truck_type : values.truck_type?values.truck_type.trim():"",
        add_by : data._id
      };

    

      try{
        axios
        .post(`${process.env.REACT_APP_API_URL}/admin/add_truck`, truckData)
        .then((res) => {
          if (res.data.status) {
             message.success({ content: res.data.message, key: "loading" });
             
             try{
              socket.emit("new_truck",truckData)
            }
            catch(e){
              console.log(e)
            }
            fetchTruckData()
            form.resetFields();
            setLoading(false)
          } else {
            message.error({ content: res.data.message, key: "loading" });
            setLoading(false)
          }
        });
      }catch(e){
        alert("Server Error!")
      }
    
  };
  const fetchTruckData = () => {
    message.loading({ content: "Loading...", key: "loading" });
    axios
      .post(`${process.env.REACT_APP_API_URL}/admin/fetch_waiting_truck`, {
        page: page,
      })
      .then((res) => {
        if (res.data.status) {

          setTotalPage(res.data.totalRows);

          dispatch(waitingTruckDataAction(res.data.data));
         
          message.success({ content: "Loaded", key: "loading" });
        } else {
          message.error({ content: res.data.message, key: "loading" });
        }
      });
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="form-card bg-white">
            <div className="card-heading">
              <p>Add New Truck</p>
            </div>
            <div>
              <Form
                form={form}
                name="form"
                initialValues={{
                  remember: true
                }}
                onFinish={onFinish}
                layout="vertical"
                autoComplete="off"
              >
                <div className="row">

                 {/* TRANSPORT COMPANY NAME */}
                  <div className="col-md-6">
                    <Form.Item
                      label="Transporter Name"
                      name="trans_name"
                      rules={[
                        {
                          pattern:/^[a-zA-Z_ ]+$/,
                          message : "Transporter name must contain alpha characters only!"
                        },
                        {
                          required: true,
                          message: "Please input your transporter name!"
                        }
                      ]}
                    >
                      <Input placeholder="Enter transporter name " />
                    </Form.Item>
                  </div>


                  {/* TRANSPORT COMPANY MOBILE */}
                  <div className="col-md-6">
                    <Form.Item
                      label="Transporter mobile"
                      name="trans_mobile"
                      
                      rules={[
                        {
                          pattern: /^[0-9]{10}$/,
                          message: "Input valid mobile number!"
                        }
                      ]}
                    >
                      <Input type="number" placeholder="Enter transporter mobile" />
                    </Form.Item>
                  </div>
                </div>

                <div className="row">
          

                 {/* TRUCK NUMBER */}

                  <div className="col-md-6 ">
                    <Form.Item
                      label="Truck No"
                      name="truck_no"
                      rules={[
                        {
                          pattern:/^[A-Z0-9_ ]+$/,
                          message : "Truck Number can contain alpha numeric characters only!"
                        },
                        {
                          required: true,
                          message: "Please input your truck no!"
                        }
                      ]}
                    >
                      <Input placeholder="Enter truck no" onInput={(e) => e.target.value = ( e.target.value).toUpperCase()}/>
                    </Form.Item>
                  </div>

                  {/*  DRIVER NAME */}



                  <div className="col-md-6 ">
                    <Form.Item
                      label="Driver Name"
                      name="driver_name"
                      rules={[
                        {
                          pattern:/^[a-zA-Z_ ]+$/,
                          message : "Driver name must contain alpha characters only!"
                        }
                      ]}
                    >
                      <Input placeholder="Enter driver name "/>
                    </Form.Item>
                  </div>


                  {/*  DRIVER MOBILE NUMBER */}


                  <div className="col-md-6 ">
                    <Form.Item
                      label="Driver Mobile"
                      name="driver_no"
                      rules={[
                        {
                          pattern: /^[0-9]{10}$/,
                          message: "Input valid mobile number!"
                        }
                      ]}
                    >
                      <Input type="number" placeholder="Enter driver mobile no "/>
                    </Form.Item>
                  </div>
                  <div className="col-md-6">

                  <Form.Item
                      label = "Truck Type"
                      name="truck_type"
                      rules={[{ required: true, message: 'Truck type is required!' }]}
                    >
                      <Select placeholder = "Select Truck Type" style={{width : '100%'}}>

                        <Option value = 'jack'>Jack </Option>
                        <Option value = 'manual'> Manual </Option>
                    
                      </Select>
                    </Form.Item>

                  </div>
                </div>
                <div className="col-md-6">
                  <Form.Item>
                    <Button htmlType="submit" className="form-btn border-0">
                     {loading?<LoadingSpinner/>:"Submit"} 
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        
          <WaitingTruck some ={some} countRows = {totalPage} />
         
      
      </div>
    </div>
  );
}

export default AddTruck;
