import React from 'react';

import {MdOutlineDoubleArrow} from "react-icons/md"
import { Link } from 'react-router-dom';

function SupDashCard(props) {
        return (
             
                 <div className="form-card dash-card-wrapper">
                         
                        <div className={`dash-card-icon ${props.iconBg}`}>
                                {props.icon}    
                        </div>
                        <div className='sv-dash-card-data'>
                                
                                <p>{props.infoTitle} </p>
                                {
                                        props.info ? <p>{props.supCount}</p>:
                                        <>
                                          <p>Total : {props.totalCount}</p>
                                          <p>By You : {props.supCount}</p></>
                                }
                              
                               
                        </div>

                        <div className="dash-card-footer ">

                                <Link to = {props.linkPath} className ="font-black fw-500 text-capitalize">{props.linkTitle} <MdOutlineDoubleArrow className='font-black fs-sm'/> </Link>
                                
                        </div>
                </div>
        );
}

export default SupDashCard;