import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { Input, Select, message, Table, Pagination ,Switch } from "antd";
import { useDispatch } from "react-redux";
import { ActionTypes } from "../../../redux/constants/ActionTypes";
import { CSVLink } from "react-csv";
import {BiExport} from "react-icons/bi"
import  moment  from 'moment';

const { Option } = Select;

function TruckDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  var isLoggedIn = localStorage.getItem("loggedIn")
  
  var data = null
  if(isLoggedIn)
  {
    // eslint-disable-next-line no-unused-vars
    data = JSON.parse(localStorage.getItem("adminInfo"))
  }
  else
  {
    localStorage.clear();
    dispatch({ type: ActionTypes.ADMIN_LOGOUT });
    navigate("/");
  }


  const columns = [
    {
      title: 'S_No',
      key: 'sno',
      width: '20px',
      render: (text, object, index) =>{return (page - 1) * 10 + index + 1}
    
    },
    {
      title: "Truck No",
      dataIndex: "truck_number",
      key: "truck_no",
    },
    {
      title: "Driver Name",
      dataIndex: "driver_name",
      key: "driver_name",
      render : (text) => (<span className="text-capitalize">{text}</span>)
    },
    {
      title: "Driver Mobile",
      dataIndex: "driver_number",
      key: "driver_number",
      
    },
    {
      title: "Transporter Mobile",
      dataIndex: "transporter_number",
      key: "transporter_mobile"
    },
      {
        title : "Status",
        dataIndex : "status" ,
        key : "status",
        render : (text) => (<span className={`${text==="cancel" ? 'font-red' : 'text-success'} text-capitalize fw-500`}>{text}</span>)
      },

    
  ];

  const [truckData, setTruckData] = useState([]);

  const[csvData, setcsvData] = useState([])

  const [sortingOrder,setSortingOrder] = useState("desc")

  const[query,setQuery] = useState("")

  const [filterValue, setFilterValue] = useState('truck_number');

  const [sortBy, setSortBy] = useState("createdAt");


  const queryPage = useLocation().search.match(/page=([0-9]+)/, "");
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const [page, setPage] = useState(currentPage);
  // eslint-disable-next-line no-unused-vars
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(1);

  const pageChange = (newPage ,pageSize) => {
    if (newPage > 0 && newPage <= totalPage) {
      currentPage !== newPage && navigate(`/truckdetails?page=${newPage}`);
      setPage(newPage);
      //       fetchData();
    }
  };

  const onExportCsv = () =>{

    if(truckData){

      if(truckData.length<1) {
        message.warning({key:"loading",content:"No data to export!"})
      }
      else{
        message.success({key:"loading",content:"Data Exported"})
      }
    
    }

  }

  const fetchTruckData = () => {
    message.loading({ content: "Loading...", key: "loading" });
    axios
      .post(`${process.env.REACT_APP_API_URL}/admin/fetch_truck`, {
        page: page,
        size: pageSize,
        filterColumn:filterValue,
        query:query,
        sortBy : sortBy,
        sortingOrder :sortingOrder
      })
      .then((res) => {
        if (res.data.status) {
          res.data.data.forEach((element, index)=>{
            res.data.data[index].key = index;
          })
          setTruckData(res.data.data);
          setTotalPage(res.data.totalRows);

          message.success({ content: "Loaded", key: "loading" });
        } else {
          message.error({ content: res.data.message, key: "loading" });
        }
      });
  };

  const handleSorting = (value) =>{
    if(value===true){
      setSortingOrder("asc")
    }else{
      setSortingOrder("desc")
    }
  }
  
  useEffect(() => {
    fetchTruckData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page,filterValue,query,sortBy,sortingOrder]);
  // const onShowSizeChange = (current, pageSize) => {
  //   console.log()
  // };


  useEffect(()=>{
    var truckCsvData = []

    truckData.forEach((element,key)=>{

      truckCsvData.push({
        s_no:key+1,
        transporter_name : element.transporter_name,
        transporter_number:element.transporter_number,
        truck_number:element.truck_number,
        driver_name:element.driver_name,
        driver_number:element.driver_number,
        truck_type:element.truck_type,
        status:element.status,
        addedtAt:element.createdAt?moment(element.createdAt).format('DD-MM-YYYY , hh:mm:ss'):"",
        addedBy:element.addedBy,
        loading_time:element.loading_time?moment(element.loading_time).format('DD-MM-YYYY , hh:mm:ss'):"",
        loadedBy:element.loadedBy,
        cancel_time:element.cancel_time?moment(element.cancel_time).format('DD-MM-YYY ,hh:mm:ss'):"",
        canceledBy:element.canceledBy?element.canceledBy:""

      })
    })

    setcsvData(truckCsvData)
  },[truckData])

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
        <div className="form-card bg-white p-3">
      <div className="card-heading">
                <p>Truck Details</p>
      </div>
      <div className="row my-3">
        <div className="col-md-3">
          <label className ="custom-label">Sort By :</label>
          <Select
            onChange={(value) => setSortBy(value)}
            placeholder ="Sort By:"
            style={{ width: "100%" }} 
            value ={sortBy}
          >
            <Option value ="createdAt">Default</Option>
            <Option value="transporter_name">Transport name</Option>
            <Option value="truck_number">Truck number</Option>
            <Option value="driver_name">Driver Name</Option>
            <Option value="loading_time">Loaded At</Option>
            <Option value="cancel_time">Canceled At</Option>
          </Select>
        </div>
        <div className="col-md-3 ">
        <label className ="custom-label">Sorting order :</label>
        <Switch
              checkedChildren={sortingOrder}
              unCheckedChildren={sortingOrder}
              className="d-block"
              // defaultChecked
              onChange={handleSorting}/>
                    
        </div>
        <div className="col-md-3">
        <label className="custom-label">filter By:</label>
          <Select
            onChange={(value) => setFilterValue(value)}
            placeholder ="Filter By:"
            style={{ width: "100%" }} 
            value ={filterValue}
          >
            <Option value="truck_number">Truck No</Option>
            <Option value="driver_name">Driver Name</Option>
            <Option value="driver_number">Driver Mobile</Option>
            <Option value="transporter_name">Transporter name</Option>
            <Option value="transporter_number">Transporter Mobile</Option>
            <Option value="truck_type">Truck Type</Option>
            <Option value="status">Status</Option>
            <Option value="addByData.name">Added By</Option>
            {/* <Option value="createdAt">Added At</Option> */}
            <Option value="loadedBy.name">Loaded By</Option>
            {/* <Option value="loading_time">Loaded At</Option> */}
            <Option value="cancelBy.name">Canceled By</Option> 
            {/* <Option value="cancel_time">Canceled At</Option> */}
           
          </Select>
        </div>
          <div className="col-md-3">
            <label className="custom-label">Type Query :</label>
            <Input type="text" placeholder="Type Query..." onChange ={(e)=>setQuery(e.target.value)}/>
          </div>
      </div>
      <div className="row">

    {  data ?
          data.role === "admin" || data.role === "subadmin" ?
          <div className="col-md-12 text-end mb-2">
                {csvData.length > 0 ? <CSVLink
                  data={csvData}
                  onClick={onExportCsv}
                  filename="truck_details.csv"
                  className="btn btn-sm btn-outline-dark"
                >
                  <BiExport className="mb-1 fs-6" /> Export CSV
                </CSVLink> : <button className="btn btn-sm btn-outline-dark" onClick={onExportCsv}><BiExport className="mb-1 fs-6" /> Export CSV</button>}
              </div>
              :"":""

    }
       

        <div className="col-md-12">
          <Table
            className="bg-white"
            columns={columns}
            dataSource={truckData}
            pagination={false}
            style={{ overflow: "auto" }}
            size="small"
            expandable={{
              expandedRowRender: record =><div className = "row text-center fw-500 mx-0">
                  <div className="col-md-4">
                     <p> <span>Transporter Name : </span><span className="text-capitalize"> {record.transporter_name?record.transporter_name:null}</span></p>
                     <p> <span>Truck Type : </span><span className="text-capitalize"> {record.truck_type?record.truck_type:null}</span></p>
                     </div>
                  <div className="col-md-4">
                  <p> <span>Added at : </span><span> {record.createdAt ? moment(record.createdAt).format('DD-MM-YYYY hh:mm:ss'): null}</span> </p>
                    <p><span>Added By : </span><span className="text-capitalize"> { record.addedBy ?record.addedBy :null}</span>
                    </p>
                </div>


                {
                  record.status === "loaded" ?
                  <div className="col-md-4">
              
                  { record.loading_time? <p><span>Loaded At : </span><span className="text-capitalize"> {moment(record.loading_time).format('DD-MM-YYYY hh:mm:ss') }</span>
                      </p>: null}
                     {record.loadedBy ? record.loadedBy[0]? <p><span>Loaded By : </span><span className="text-capitalize"> {record.loadedBy[0] }</span>
                      </p>: null:null}
   
                  </div>
                  : null
                }
            
                {
                  
                  record.status === "cancel" ?    
                  <div className="col-md-4">
                  { record.cancel_time? <p><span>Canceled At : </span><span className="text-capitalize"> {moment(record.cancel_at).format('DD-MM-YYYY hh:mm:ss') }</span>
                      </p>: null}
                  { record.canceledBy ?record.canceledBy[0]?<p><span >Truck Cancel By : </span><span className="text-capitalize"> {record.canceledBy[0]}</span>
                  </p>:null :null}
                  { record.cancel_reason ?<p><span >Cancel Reason : </span><span className="text-capitalize"> {record.cancel_reason}</span>
                  </p>:null}
                  </div> :null
                  
                }
             
                </div>
               ,
            }}
          />
        </div>
        <div className="col-md-12">
          <Pagination
            className="bg-white p-2 mt-3 text-center"
            onChange={pageChange}
            defaultCurrent={1}
            showSizeChanger = {false}
            total={totalPage}
          />
        </div>
      </div>
        
      </div>
          
          </div>  
      </div>
    
    </div>
  );
}

export default TruckDetails;
