import { combineReducers} from "redux";
import {sidebarStatusReducer,unseenMessageReducer } from "./LayoutReducer";
import { authReduce } from './authReducer';
import { firmDataReducer,waitingTruckDataReducer } from "./dataReducer";
import { socketReducer } from "./socketReducer";

export const reducers = combineReducers({
  
    sidebar:sidebarStatusReducer,
    auth:authReduce,
    firmData :firmDataReducer,
    waitingData : waitingTruckDataReducer,
    unseenMessage:unseenMessageReducer,
    socket_info : socketReducer
    
})