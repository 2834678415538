/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { Form,Input, Select, message, Table, Pagination ,Switch , Image, Button, Modal} from "antd";
import { useDispatch } from "react-redux";
import { ActionTypes } from "../../../redux/constants/ActionTypes";
import  moment  from 'moment';
import { CSVLink } from "react-csv";
import {BiExport} from "react-icons/bi"

const { Option } = Select;

function ArrivedTrip() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  var isLoggedIn = localStorage.getItem("loggedIn")
  
  var data = null

  if(isLoggedIn)
  {
    data = JSON.parse(localStorage.getItem("adminInfo"))
  }
  else
  {
    localStorage.clear();
    dispatch({ type: ActionTypes.ADMIN_LOGOUT });
    navigate("/");
  }


  const columns = [
    {
      title: 'S_No',
      key: 'sno',
      width: '20px',
      render: (text, record, index) =>{return (page - 1) * 10 + index + 1}
    
    },
    {
      title: "Truck",
      dataIndex: "truck",
      key: "truck_no",
      render : (text,record,index) =>(<span>{record.truck[0] } {record.redirected?<span className="badge rounded-pill bg-primary font-white trounded-pill">Redirected</span> : ""}</span>)
    },
    {
      title: "Firm",
      dataIndex: "firm",
      key: "firm_no",
      render : (text,record,index) =>{ return record.firm[0] }
    },
    {
      title : "MR No",
      dataIndex : "mr_no" ,
      key : "mr_no"
    }, 
    {
      title : "TP No",
      dataIndex : "tp_no" ,
      key : "tp_no"
    },
      {
        title : "TP Weight",
        dataIndex : "tp_weight" ,
        key : "tp_weight",
        render : (text,record) =>(<span className = "text-uppercase">{record.tp_weight? record.tw_measure ? record.tp_weight+ " " + record.tw_measure: record.tp_weight :false}</span>)
      },
      {
        title : "Status",
        dataIndex : "status",
        key : "status",
        render : (text,record) =>(
                <span className={`${record.status === "cancel" ? 'text-danger' : 'text-success'}  fw-500 text-capitalize`}>{record.status}</span>
           )
      },
      {
        title : "Action",
        dataIndex:"action",
        key:"action",
        className :data.role === "supervisor" || data.role === "subadmin"? "" :"d-none",
        render:(text,record) =>(
          <div>
          <Button className="btn btn-sm outline-black font-black fw-500 my-1" onClick= {()=>navigate(`/tripinfo/${record._id}?status=proceed`)}>Proceed</Button>
          <Button className="btn btn-sm bg-red text-white ms-2 my-1" onClick= {()=>navigate(`/tripinfo/${record._id}?status=cancel`)}>Cancel</Button>
          </div>
        )
      }
  ];

  const [truckData, setTruckData] = useState([]);

  const[csvData, setcsvData] = useState([])

  const [sortingOrder,setSortingOrder] = useState("desc")

  const[query,setQuery] = useState("")

  const [filterValue, setFilterValue] = useState("truckDetails.truck_number");

  const [sortBy, setSortBy] = useState("arrived_at");


  const queryPage = useLocation().search.match(/page=([0-9]+)/, "");
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const [page, setPage] = useState(currentPage);
  // eslint-disable-next-line no-unused-vars
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(1);

  
  
  const pageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPage) {
      currentPage !== newPage && navigate(`/tripdetails?page=${newPage}`);
      setPage(newPage);
      //       fetchData();
    }
  };

  const onExportCsv = () =>{

    if(truckData){

      if(truckData.length>0){
        message.success({key:"loading",content:"Data exported."})
      }
      else{
        message.warning({key:"loading",content:"No data to export!"})
      }

   
    }

  }


  const fetchTripData = () => {
    message.loading({ content: "Loading...", key: "loading" });
    axios
      .post(`${process.env.REACT_APP_API_URL}/admin/fetch_trips`, {
        status:"arrived",
        page: page,
        size: pageSize,
        filterColumn:filterValue,
        query:query,
        sortBy : sortBy,
        sortingOrder :sortingOrder
      })
      .then((res) => {
        if (res.data.status) {
          res.data.data.forEach((element, index)=>{
            res.data.data[index].key = index;
          })
          setTruckData(res.data.data);
          setTotalPage(res.data.totalRows);
          message.success({ content: "Loaded", key: "loading" });
        } else {
          message.error({ content: res.data.message, key: "loading" });
        }
      });
  };

  const handleSorting = (value) =>{
    if(value===true){
      setSortingOrder("asc")
    }else{
      setSortingOrder("desc")
    }
  }



  // CANCEL TRIP

  useEffect(() => {
        fetchTripData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page,filterValue,query,sortBy,sortingOrder]);


  useEffect(()=>{
    var truckCsvData = []

    truckData.forEach((element,key)=>{

     
      truckCsvData.push({
        s_no:key+1,
        mr_no: element.mr_no,
        loading_time: element.createdAt
          ? moment(element.createdAt).format("DD-MM-YYYY , hh:mm:ss")
          : "",
        supplier: element.supplier,
        invoice_no: element.invoice_no,
        material_details: element.material_details ,
        material_type: element.material_type ,
        tp_weight: element.tp_weight + element.tw_measure,
        tp_weight_measurement: element.tw_measure,
        loading_weight: element.loading_weight + element.lw_measure,
        loading_weight_measurement: element.lw_measure,
        transporter_name : element.transporter_name,
  
        truck: element.truck,

        trip: element.trip,

        tp_no: element.tp_no,

        firm: element.firm,
       
        vehicle_type:element.vehicle_type,
        
        redirectBy: element.redirectBy,
        redirect_time: element.on_site_cancel_time
          ? moment(element.on_site_cancel_time).format(
              "DD-MM-YYYY , hh:mm:ss"
            )
          : "",
        redirected_from: element.redirected_from,
        redirect_reason: element.on_site_cancel_reason,

        loaded_by: element.loaded_by,
       
        dispatch_time: element.createdAt
        ? moment(element.dispatch_time).format("DD-MM-YYYY , hh:mm:ss")
        : "",
        dispatch_by: element.dispatch_by,

        arrival_time:element.arrived_at?moment(element.arrived_at).format('DD-MM-YYYY , hh:mm:ss'):"",
        arrival_by:element.arrival_marked_by,

        status: element.status,


       
       

      })
    })

    setcsvData(truckCsvData)
  },[truckData])
 
  return (
    <div className="container-fluid">
     

      <div className="bg-white p-3">
      <div className="card-heading">
                <p>Arrived Trips</p>
      </div>
      <div className="row my-3">
        <div className="col-md-3">
          <label className ="custom-label">Sort By :</label>
          <Select
            onChange={(value) => setSortBy(value)}
            placeholder ="Sort By:"
            style={{ width: "100%" }} 
            value ={sortBy}
          >
            <Option value="arrived_at">Arrived At</Option>
            <Option value="truckDetails.truck_number">Truck</Option>
            <Option value="mr_no">MR No</Option>
            <Option value="tp_no">TP No</Option>
            <Option value="tp_weight">TP weight</Option>
            
            <Option value ="createdAt">Loaded At</Option>
            <Option value="loading_weight">Loading weight</Option>
            <Option value="invoice_no">Invoice number</Option>
            <Option value="arrived_at">Arrived At</Option>
            <Option value="dispatch_time">Dispatched At</Option>
            <Option value="on_site_cancel_time">Redirected At</Option>
          </Select>
        </div>
        <div className="col-md-3 ">
        <label className ="custom-label">Sorting order :</label>
        <Switch
              checkedChildren={sortingOrder}
              unCheckedChildren={sortingOrder}
              className="d-block"
              // defaultChecked
              onChange={handleSorting}/>
                    
        </div>
        <div className="col-md-3">
        <label className="custom-label">filter By:</label>
          <Select
            onChange={(value) => setFilterValue(value)}
            placeholder ="Filter By:"
            style={{ width: "100%" }} 
            value ={filterValue}
          >
          <Option value="truckDetails.truck_number">Truck</Option>
          <Option value="truckDetails.transporter_name">Transporter Name</Option>
            <Option value="firmDetails.name">Firm</Option>
            {/* <Option value="material_details">Material Details</Option>
            <Option value="material_type">Material Type</Option> */}
            <Option value="mr_no">MR No</Option>
            <Option value="tp_no">TP No</Option>
            <Option value="tw_measure">TP Weight</Option>
            <Option value="status">Status</Option>
            <Option value="supplier">Supplier</Option>
            <Option value="invoice_no">Invoice No</Option>
            <Option value="lw_measure">Loading Weight</Option>
            <Option value="trip">Trip/Route</Option>
            <Option value="redirected_from">Redirect From</Option>
            <Option value="on_site_cancel_reason">Redirect Reason</Option>
            <Option value="loadedBy.name">Loaded By</Option>
            <Option value="dispatchBy.name">Dispatched By</Option>
            <Option value="arrivalMarkedBy.name">Arrival By</Option>
            <Option value="redirectBy.name">Redirected By</Option>
            {/* <Option value="createdAt">Loaded At</Option> */}
            {/* <Option value="cancel_at">Canceled At</Option> */}
          </Select>
        </div>
          <div className="col-md-3">
            <label className="custom-label">Type Query :</label>
            <Input type="text" placeholder="Type Query..." onChange ={(e)=>setQuery(e.target.value)}/>
          </div>
      </div>
      <div className="row">

        {
          data ? data.role ==="admin" || data.role === "subadmin" ?
          <div className="col-md-12 text-end mb-2">
          {csvData.length > 0 ? <CSVLink
            data={csvData}
            onClick={onExportCsv}
            filename="arrived_trips.csv"
            className="btn btn-sm btn-outline-dark"
          >
            <BiExport className="mb-1 fs-6" /> Export CSV
          </CSVLink> : <button className="btn btn-sm btn-outline-dark" onClick={onExportCsv}> <BiExport className="mb-1 fs-6" /> Export CSV</button>}
        </div>:"":""
        }

      


        <div className="col-md-12">
          <Table
            className="bg-white"
            columns={columns}
            dataSource={truckData}
            pagination={false}
            style={{ overflow: "auto" }}
            size="small"
            expandable={{
              expandedRowRender: record =><div className = "row text-center fw-500 mx-0">
                  <div className="col-md-2">
                      <p><span>Material Details: </span><span className="text-capitalize"> { record.material_details ? record.material_details : null}</span></p>
                      <p><span>Material Type: </span><span className="text-capitalize"> { record.material_type ? record.material_type : null}</span></p>
                    </div>
                  <div className="col-md-2">
                  <p><span>Supplier : </span><span className="text-capitalize"> { record.supplier ? record.supplier : null}</span></p>
                  <p><span>Invoice No : </span><span> { record.invoice_no ? record.invoice_no : null}</span></p>
                
                  </div>

                  <div className="col-md-2">
                  <div> <span>Invoice File : </span><span> {record.invoice_file? <Image src = {`${process.env.REACT_APP_API_URL+"/"+record.invoice_file}`} width ={"60px"} height = {"60px"} /> : null}</span></div>
                  </div>


                  <div className="col-md-2">
                  <div> <span>Weighbridge Slip : </span><span> {record.weight_bridge_slip? <Image src = {`${process.env.REACT_APP_API_URL+"/"+record.weight_bridge_slip}`} width ={"60px"} height = {"60px"} /> : null}</span></div>
                  </div>
                  {record.optional_img ?
                  <div className="col-md-2">
                     <div> <span>Optional Image: </span><span> <Image src = {`${process.env.REACT_APP_API_URL+"/"+record.optional_img}`} width ={"60px"} height = {"60px"} /> </span></div> 
                  </div>: null} 

                  <div className="col-md-2">
                 <p><span>Loading weight: </span><span className="text-uppercase"> { record.loading_weight ? record.lw_measure? record.loading_weight + " " + record.lw_measure: record.loading_weight : null}</span></p>
                  <p><span>Trip/Route: </span><span className="text-capitalize"> { record.trip ? record.trip : null}</span></p>
                 </div>
                
                 
                 <div className="col-md-2">
                 <p> <span>Dispatched At : </span><span> {record.dispatch_time ? moment(record.dispatch_time).format('DD-MM-YYYY hh:mm:ss'): null}</span></p> 
                 <p> <span>Dispatched By : </span><span className="text-capitalize"> { record.dispatch_by ?record.dispatch_by[0]? record.dispatch_by[0]:null :null}</span></p> 
                 </div>
                 <div className="col-md-2">
                 <p> <span>Loaded At : </span><span> {record.createdAt ? moment(record.createdAt).format('DD-MM-YYYY hh:mm:ss'): null}</span></p> 
                 <p> <span>Loaded By : </span><span className="text-capitalize"> { record.loaded_by ?record.loaded_by[0]? record.loaded_by[0]:null :null}</span></p> 
                 </div>

                 <div className="col-md-2">
                 <p><span>Loading weight: </span><span className="text-uppercase"> { record.loading_weight ? record.lw_measure? record.loading_weight + " " + record.lw_measure: record.loading_weight : null}</span></p>
                  <p><span>Trip/Route: </span><span className="text-capitalize"> { record.trip ? record.trip : null}</span></p>
                 </div>

                 <div className="col-md-2">
                 <p> <span>Arrived At: </span><span> {record.arrived_at ? moment(record.arrived_at).format('DD-MM-YYYY hh:mm:ss'): null}</span></p> 
                 <p> <span>Arrival Marked By : </span><span className="text-capitalize">
                    { record.arrival_marked_by ?record.arrival_marked_by[0]? record.arrival_marked_by[0]:null :null}
                    </span>
                    </p> 
                 </div>

  {

                      record.redirected ?  <> <div className="col-md-2">
                      <p> <span>Redirect From: </span><span> {record.redirected_from ? record.redirected_from: null}</span></p> 
                      <p> <span>Redirect Reason: </span><span> {record.on_site_cancel_reason ? record.on_site_cancel_reason: null}</span></p> 
                     </div> 

                     <div className="col-md-2">
                      <p> <span>Redirect Time: </span><span> {record.on_site_cancel_time ? moment(record.on_site_cancel_time).format('DD-MM-YYYY hh:mm:ss'): null}</span></p> 
                      <p> <span>Redirected By: </span><span> {record.redirectBy ? record.redirectBy[0] ? record.redirectBy[0] : null : null}</span></p> 
                     </div> 
                     
                     </>: ""

                    }
              

               

                </div>
            }}
          />
        </div>
        <div className="col-md-12">
          <Pagination
            className="bg-white p-2 mt-3 text-center"
            // showSizeChanger
            // onShowSizeChange={onShowSizeChange}
            onChange={pageChange}
            defaultCurrent={1}
            total={totalPage}
          />
        </div>
      </div>
        
      </div>
    </div>
  );
}

export default ArrivedTrip;
