import React from 'react';

function LoadingSpinner() {
        return (
                // <div class="Loader">
                   
                // </div>
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        );
}

export default LoadingSpinner;