import {ActionTypes} from "../constants/ActionTypes"



export const firmDataAction = (data) =>{
    return {
        type:ActionTypes.FIRM_DATA,
        payload:data
    }
}

export const waitingTruckDataAction = (data) =>{
    return {
        type:ActionTypes.WAITING_TRUCK_DATA,
        payload:data
    }
}
