import React,{useState , useEffect, useContext} from 'react';
import axios from "axios";
import {message} from "antd";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ActionTypes } from './../../redux/constants/ActionTypes';
import moment from "moment";
import {SocketContext} from "../../Socket/Socket"

function Messages() {

        const dispatch = useDispatch()

        const navigate = useNavigate()
        
        const socket = useContext(SocketContext)

        var data = null;

        var isLoggedIn = localStorage.getItem("loggedIn");
      
        if (isLoggedIn) {
          data = JSON.parse(localStorage.getItem("adminInfo"));
        } else {
          localStorage.clear();
          dispatch({ type: ActionTypes.ADMIN_LOGOUT });
          navigate("/");
        }

        const[messageInfo,setMessageInfo] = useState([])

        const fetchMessageInfo = () =>{
                axios.post(`${process.env.REACT_APP_API_URL}/admin/fetchmessages`,{id:data._id}).then(res =>{
                        if(res.data.status){

                                setMessageInfo(res.data.data)
                               
                        }
                        else{
                                message.error(res.data.message)
                        }
                })
        }

        const updateSeenStatus = () =>{
                axios.post(`${process.env.REACT_APP_API_URL}/admin/update_seen_status`,{userId:data._id}).then(res =>{
                        if(res.data.status){
                                dispatch({ type: "UNSEEN_MESSAGE", payload: 0 })
                        }
                        else{
                                message.error(res.data.message)
                        }
                })
            }

        useEffect(()=>{
                socket.on("message",(message) =>{
                        console.log(message)
                        fetchMessageInfo()  
                       }) 

                //        return setMessageInfo([])
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[socket])

       
       

   
        useEffect(()=>{
                
                fetchMessageInfo()
                // updateSeenStatus()

                return setMessageInfo([])
               
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[])

        useEffect(()=>{
                updateSeenStatus()

        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[messageInfo])

      
        return (
                <div className='container'>

                        <div className="row">

                                <div className="col-md-8 mx-auto">

                                         <div className="form-card back-white pb-4">

                                                <div className="card-heading">

                                                        <p>Message</p>

                                                </div>

                                        {messageInfo.map((element,index) =>{

                                                return  <div key = {index+1} className="col-md-10 form-card back-white mt-3 mx-auto">

                                                <div className="message-title d-flex justify-content-between">

                                                        <p className='text-capitalize'>{element.title?element.title : ""}</p>

                                                        <p className='text-capitalize'>{element.createdAt ? moment(element.createdAt).local().startOf('seconds').fromNow() : "" }</p>

                                                </div>

                                                <div className="message-description">

                                                        <p>{element.message ? element.message : ""}</p>
                                                        <p> Message By : {element.messageBy ? element.messageBy[0] : ""}</p>

                                                </div>

                                          </div>

                                        })}
                                             
                                        </div>

                                </div>

                        </div>
                       
                </div>
        );
}

export default Messages;