import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Upload,
  message,
  DatePicker,
  Select,
  Modal,
  Image,
} from "antd";
import moment from "moment";
import axios from "axios";
import LoadingSpinner from "../../../components/LoadingSpinner";

const { Option } = Select;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function EditUser() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [userData, setUserData] = useState([]);

  const params = useParams();

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const onFinish = (values) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("name", values.name.trim());
    formData.append("mobile", values.mobile);
    formData.append("email", values.email.trim());
    formData.append(
      "dob",
      values.dob ? moment(values.dob).format("DD-MM-YYYY") : null
    );
    formData.append("role", values.role.trim());
    formData.append("id", params.id.trim());

    if (fileList.length > 0) {
      formData.append("profile_img", fileList[0].originFileObj);
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/admin/edit_adminprofile`,
        formData
      )
      .then((res) => {
        if (res.data.status) {
          setFileList([]);
          fetchUserData();
          message.success({ content: res.data.message, key: "some" });
          setLoading(false);
          // alert(res.data.message)
        } else {
          message.error({ content: res.data.message, key: "some" });
          setLoading(false);
        }
      });
  };

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.preview);
    setPreviewVisible(true);
  };

  const handleCancel = () => setPreviewVisible(false);

  const fetchUserData = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/admin/fetch_admin_by_id`, {
        id: params.id,
      })
      .then((res) => {
        if (res.data.status) {
          setUserData(res.data.data);
          // message.success({ content: res.data.message, key: "some" });
          // alert(res.data.message)
        } else {
          message.error({ content: res.data.message, key: "some" });
        }
      });
  };

  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      name: userData.name ? userData.name.trim() : null,
      mobile: userData.mobile ? userData.mobile : null,
      email: userData.email ? userData.email.trim() : null,
      dob: userData.dob ? moment(userData.dob) : null,
      role: userData.role ? userData.role.trim() : null,
    });
  }, [form, userData]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
            <img alt="example" style={{ width: "100%" }} src={previewImage} />
          </Modal>
          <div className="form-card bg-white">
            <div className="card-heading">
              <p>Update User</p>
            </div>
            <div>
              <Form
                form={form}
                name="login"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                layout="vertical"
                autoComplete="off"
              >
                <div className="row">
                  <div className="col-md-12 d-flex">
                    {userData.profilePic ? (
                      <div className="me-3">
                        <Image
                          src={
                            process.env.REACT_APP_API_URL +
                            "/" +
                            userData.profilePic
                          }
                          width={100}
                          height={100}
                        />
                      </div>
                    ) : null}

                    <div>
                        <Upload
                          listType="picture-card"
                          fileList={fileList}
                          onPreview={handlePreview}
                          onChange={onChange}
                          beforeUpload={() => false}
                        >
                          {fileList.length < 1 && "+ Upload"}
                        </Upload>
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <Form.Item
                      label="User Name"
                      name="name"
                      rules={[
                        {
                          pattern: /^[a-zA-Z0-9_ ]+$/,
                          message:
                            "User name can contain alphanumeric characters only!",
                        },
                        {
                          required: true,
                          message: "Please input user name!",
                        },
                      ]}
                    >
                      <Input placeholder="Enter Username " />
                    </Form.Item>
                  </div>

                  <div className="col-md-6">
                    <Form.Item
                      label="Mobile"
                      name="mobile"
                      rules={[
                        {
                          pattern: /^[0-9]{10}$/,
                          message: "Input valid mobile number!",
                        },
                        {
                          required: true,
                          message: "Please input your mobile number!",
                          // whitespace:true,
                        },
                      ]}
                    >
                      <Input type="number" placeholder="Enter Your Mobile" />
                    </Form.Item>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 ">
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        {
                          type: "email",
                          message: "Please input valid email!",
                        },
                        {
                          required: true,
                          message: "Please input your email!",
                        },
                      ]}
                    >
                      <Input placeholder="Enter Your Email" />
                    </Form.Item>
                  </div>
                  <div className="col-md-6 ">
                    <Form.Item
                      label="Date of Birth"
                      name="dob"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please input your dob!"
                      //   }
                      // ]}
                    >
                      <DatePicker
                        placeholder="Enter your dob"
                        className="w-100"
                      />
                      {/* <Input placeholder="Enter your username "/> */}
                    </Form.Item>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Item
                      label="Role"
                      name="role"
                      rules={[
                        {
                          required: true,
                          message: "Please input your role!",
                        },
                      ]}
                    >
                      {/* <Input placeholder="Enter Your Mobile" /> */}
                      <Select placeholder="Select Role" className="w-100">
                        <Option value="admin">Admin</Option>
                        <Option value="subadmin">Sub Admin</Option>
                        <Option value="supervisor">Supervisor</Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>

                {/* <div className="row">
                  <div className="card-heading">
                    <p>Address</p>
                  </div>
                </div> */}

                <div className="col-md-6">
                  <Form.Item>
                    <Button htmlType="submit" className="form-btn border-0">
                      {loading ? <LoadingSpinner /> : "Update"}
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditUser;
