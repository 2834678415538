import React ,{useState} from "react";
import { Form, Input, Button,message } from "antd";
import { useParams} from "react-router-dom";
import axios from "axios";
import LoadingSpinner from './../../components/LoadingSpinner';

function ResetPassword() {

  const[loading,setLoading] = useState(false)
  const [form] = Form.useForm();
  const params = useParams();
  // const navigate = useNavigate()


  const onFinish = (values) => {
    if (values.password === values.confirm_password) {
      setLoading(true)
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/admin/update_password/${params.id}/${params.date}`,
          { password: values.password }
        )
        .then((res) => {
          if (res.data.status) {
            message.success({key:"loading",content : `${res.data.message}`})
           setLoading(false)
          // navigate('/login')
          }
        });
    } else {
     message.error({key:"loading",content:"password doesn't match"})
     setLoading(false)
    }
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 px-0">
          <div className="form-bg">
            <div className="form-card p-4 col-md-4">
              <div className="col-md-12 mt-4">
                <div>
                  <Form
                    name="normal_login"
                    className="login-form"
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                  >
                    <Form.Item
                      name="password"
                      className="form-item"
                      label="new password"
                      rules={[
                        {
                          pattern:
                            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                          message:
                            "password must contain minimum eight characters, at least one letter, one number and one special character."
                        },
                        {
                          required: true,
                          message: "Please input your password!",
                          whitespace: true
                        }
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item
                      name="confirm_password"
                      className="form-item"
                      label="Confirm Password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                          whitespace: true
                        }
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <div className="col-md-12 text-center">
                      <Form.Item>
                        <Button
                          type="primary"
                          className="form-btn border-0"
                          htmlType="submit"
                          to="/"
                        >
                          {loading?<LoadingSpinner/>:"Reset Password"}
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
