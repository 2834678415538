import React, { useState, useEffect } from "react";
import { Select, message, DatePicker, Button } from "antd";
import dayjs from "dayjs";
import axios from "axios";
import moment from "moment";

const { Option } = Select;

const { RangePicker } = DatePicker;

function MaterialInfo() {
  const [firmData, setFirmData] = useState();
  const [materialInfo, setMaterialInfo] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [firmName , setFirmName] = useState("")

  // FETCH FIRMS

  const fetchFirmDetails = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/admin/get_firmData`)
      .then((res) => {
        if (res.data.status) {
          var firm = [];
          res.data.data.forEach((element, index) => {
            firm.push(
              <Option key={index + 1} value={element._id}>
                {element.name}
              </Option>
            );
          });

          setFirmData(firm);
        } else {
          message.error({ content: res.data.message, key: "some" });
        }
      });
  };

  const onFirmSelect = (value) =>{
        setFirmName(value)
       
  }
  const fetchMaterialInfo = () => {
        if(firmName){
                axios
                .post(`${process.env.REACT_APP_API_URL}/admin/materialinfo`, {
                  firmId: firmName,
                  startDate: startDate,
                  endDate: endDate,
                })
                .then((res) => {
                  if (res.data.status) {
                    setMaterialInfo(res.data.data);
                    message.success({ content: res.data.message, key: "data" });
                  } else {
                    setMaterialInfo([]);
                    message.error({ content: res.data.message, key: "data" });
                  }
                });
        }else{
                message.info({ content: 'Please select firm to check material info!', key: "data" })
        }
    
  };

  const onDatePick = (date) => {
    setStartDate(moment(date[0]).format("YYYY-MM-DD 00:00:00"));
    setEndDate(moment(date[1]).format("YYYY-MM-DD 23:00:00"));
  };

  useEffect(() => {
    fetchFirmDetails();
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-10 col-lg-6 mx-auto">
          <div className="form-card back-white">
            <div className="card-heading">
              <p>Check Material Info</p>
            </div>

            <div className="row mt-3">
              <div className="col-md-10 my-2 ">
                <RangePicker onChange={onDatePick} />
                
              </div>
              <div className="col-md-2 my-2 text-end">
              <button className="btn bg-orange text-white fw-500 btn-sm" onClick={fetchMaterialInfo}>Check</button>
              </div>
              <div className="col-md-12  mx-auto">
                <Select
                  placeholder="Select Firm To Check Info"
                  className="w-100"
                  onChange={onFirmSelect}
                >
                  {firmData}
                </Select>
              </div>
            </div>

            <div className="row pb-4">
              {materialInfo
                ? materialInfo.length > 0
                  ? materialInfo.map((element) => {
                      return (
                        <div className="col-md-12 mx-auto mt-3">
                          <div className="form-card trip-card back-white">
                            <div className="">
                              <p>
                                Measurment :
                                <span className="text-uppercase">
                                  {element._id ? element._id : ""}
                                </span>
                              </p>
                            </div>
                            <div>
                              <p>
                                {" "}
                                Total : {element.total ? element.total : ""}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : ""
                : ""}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MaterialInfo;
