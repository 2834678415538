import React,{useState} from "react";
import { Form, Input, Button,message } from "antd";
import { Link,useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ActionTypes } from "../../redux/constants/ActionTypes";
import axios from "axios";
import LoadingSpinner from './../../components/LoadingSpinner';


function Login() {

  const[loading,setLoading] = useState(false)

  const [form] = Form.useForm();

  const  navigate  = useNavigate();

  const dispatch = useDispatch()

  function handleClick() {
    navigate('/');
    // <Navigate to ="/"/>
  };

  const onFinish = (values) => {
    setLoading(true)
    axios
      .post(`${process.env.REACT_APP_API_URL}/admin/login`, {
        email: values.email,
        password: values.password
      })
      .then((res) => {
        if (res.data.status === true) {
          localStorage.setItem("adminInfo", JSON.stringify(res.data.data));
          localStorage.setItem("authToken",res.data.authtoken)
          localStorage.setItem("loggedIn",true);
          dispatch({
            type: ActionTypes.ADMIN_LOGIN_SUCCESS,
            payload: { admin: res.data.data }
          });
          setLoading(false)
          handleClick()
        }else{
          message.error({key:"loading",content: res.data.message})
          setLoading(false)
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="col-md-12 px-0">
          <div className="form-bg">
            <div className="form-card p-5 col-md-5">
              <div className="row">
                <div className="col-md-8">
                  <img src="images/as_sme-01.png" alt="Logo" />
                </div>
                <div className="col-md-4 text-end log-in-title">
                  Sign In
                </div>
                </div>
                <div className = "row">
                <div className="col-md-12 mt-3">
                  <p className="login-form-title">
                    Aggregate solutions &#38; Shree madhav enterprises
                  </p>
                </div>
             

                <div className="col-md-12 mt-3">
                  <Form
                    form = {form}
                    name="login"
                    initialValues={{
                      remember: true
                    }}
                    onFinish={onFinish}
                    layout="vertical"
                    autoComplete="off"
                  >
                    <div className="col-md-12 ">
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Please input your email!"
                          }
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>

                    <div className="col-md-12">
                      <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Please input your password!"
                          }
                        ]}
                      >
                        <Input.Password />
                      </Form.Item>
                    </div>

                    <div className="row">
                    <div className="col-md-6">
                      <Form.Item>
                        <Button htmlType="submit" className="form-btn border-0">
                          {loading?<LoadingSpinner/>:"Submit"}
                        </Button>
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                            <p className="text-end fs-6"><Link to ="/forgot_password" className="font-black fw-500">Forgot Password ?</Link></p>
                        </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
