import React, { useState, useEffect , useContext } from "react";
import { Form, Input, Button, Radio, message, Checkbox } from "antd";
// import axios from "axios"
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ActionTypes } from "../../../redux/constants/ActionTypes";
import axios from "axios";
import { SocketContext } from "../../../Socket/Socket"

function CancelTrip(props) {
  const [form] = Form.useForm();
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const socket = useContext(SocketContext)

 
  var isLoggedIn = localStorage.getItem("loggedIn");

  var data = null;

  if (isLoggedIn) {
    data = JSON.parse(localStorage.getItem("adminInfo"));
  } else {
    localStorage.clear();
    dispatch({ type: ActionTypes.ADMIN_LOGOUT });
    navigate("/login");
  }

  const [isNewSite, setIsNewSite] = useState(false);
  const[tpEdit,setTpEdit] = useState(false)


  const OnChangeRedirectLocation = (e) => {
    if (e.target.value === "newsite") {
      setIsNewSite(true);
    } else {
      setIsNewSite(false);
    }
  };

  const onFinish = (values) => {
    const redirectInfo = {
      id: params.id,
      on_site_cancel_reason: values.cancel_reason,
      sId: data._id,
      redirected_to:
        values.redirected_to === "warehouse" ? "warehouse" : values.new_site,
      tp_number: values.tp_number ? values.tp_number : "",
      mr_number: values.mr_number ? values.mr_number : ""
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/admin/cancelonunload`,
        redirectInfo
      )
      .then((res) => {
        if (res.data.status) {
          message.success({key:"loading",content:res.data.message});
          form.resetFields();

          try{
            socket.emit("redirect_trip",redirectInfo)
          }
          catch(e){
            console.log(e)
          }

          navigate("/tripdetails");
        } else {
          message.error({key:"loading",content:res.data.message});
        }
      });
  };

  

  useEffect(() => {
    form.setFieldsValue({
      tp_number: props.tripData.tp_no ? props.tripData.tp_no : ""
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form,props]);
  return (
    <div className="row ">
      <div className="col-md-12 mt-3">
        <div className="form-card back-white">
          <div className="card-heading">
            <p>Cancel Trip</p>
          </div>

          <div>
            <Form
              form={form}
              name="form"
              initialValues={{
                remember: true
              }}
              onFinish={onFinish}
              layout="vertical"
              autoComplete="off"
            >
              <div className="row">
                <div className="col-md-6">
                  <Form.Item
                    name="cancel_reason"
                    label="Cancel Reason"
                    rules={[
                      {
                        pattern: /^[a-zA-Z _ , ]+$/,
                        message:
                          "Cancel Reason must contain alpha characters only!"
                      },
                      {
                        required: true,
                        message: "Please input your cancel reason!"
                      }
                    ]}
                  >
                    <Input.TextArea
                      autoSize={{ minRows: 3, maxRows: 3 }}
                      placeholder="Enter Cancel Reason"
                    />
                  </Form.Item>
                </div>

                <div className="col-md-6">
                  <Form.Item
                    name="redirected_to"
                    label="Redirect Location"
                    rules={[
                      {
                        required: true,
                        message: "Please selecte redirect location"
                      }
                    ]}
                  >
                    <Radio.Group onChange={OnChangeRedirectLocation}>
                      <Radio className="my-1" value={"warehouse"}>
                        Back to Warehouse
                      </Radio>
                      <Radio className="my-1" value={"newsite"}>
                        Redirect to New Site
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
              </div>
              {isNewSite ? (
                <div className="row">
                  <div className="col-md-6">
                    <Form.Item
                      name="new_site"
                      label="New Site"
                      rules={[
                        {
                          pattern: /^[a-zA-Z0-9 _ ,]+$/,
                          message:
                            "Cancel Reason must contain alpha numeric characters only!"
                        },
                        {
                          required: true,
                          message: "Please input your new site!"
                        }
                      ]}
                    >
                      <Input.TextArea
                        autoSize={{ minRows: 3, maxRows: 3 }}
                        placeholder="Enter New Site"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-3">

                  <Form.Item
                        name="tp_number"
                        label="TP Number"
                        rules={[
                          {
                            pattern: /^[a-zA-Z0-9_ ]+$/,
                            message:
                              "TP No can contain alpha numeric characters!"
                          },
                          {
                            required: true,
                            message: "Please input tp no!"
                          }
                        ]}
                      >
                        <Input
                          placeholder="Enter TP Number"
                          disabled = {tpEdit ? false :true}
                          onInput={(e) =>
                            (e.target.value = e.target.value.toUpperCase())
                          }
                        />
                      </Form.Item>
                      <Checkbox checked={tpEdit} onChange={()=> setTpEdit(!tpEdit)}>Edit TP Number</Checkbox>
                  </div>
                  <div className="col-md-3">
                    <Form.Item
                      name="mr_number"
                      label="MR Number"
                      rules={[
                        {
                          pattern: /^[a-zA-Z0-9_ ]+$/,
                          message:
                            "MR No can contain alpha numeric characters only!"
                        },
                        {
                          required: true,
                          message: "Please input MR number!"
                        }
                      ]}
                    >
                      <Input
                        placeholder="Enter MR Number"
                        onInput={(e) =>
                          (e.target.value = e.target.value.toUpperCase())
                        }
                      />
                    </Form.Item>
                  </div>
                </div>
              ) : null}

              <div className="col-md-12">
                <Form.Item>
                  <Button htmlType="submit" className="form-btn border-0">
                    Submit
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CancelTrip;
