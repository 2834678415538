import React ,{useState} from "react";
import { VscLock } from "react-icons/vsc";
import { Form, Input, Button,message } from "antd";
import axios from "axios";
import {BiArrowBack} from "react-icons/bi"
import { Link } from 'react-router-dom';
import LoadingSpinner from './../../components/LoadingSpinner';

function ForgotPassword() {
  const [form] = Form.useForm();
  const[loading,setLoading] = useState(false)

  const onFinish = (values) => {
    setLoading(true)
    axios
      .post(`${process.env.REACT_APP_API_URL}/admin/reset_password`, {
        email: values.email
      })
      .then((res) => {
        if (res.data.status) {
         message.success({key:"loading",conent : "Link has been sent to registered mail"})
         setLoading(false)
         form.resetFields()
        } else {
          message.error({key:"loading", content : `${res.data.message}`});
          setLoading(false)
        }
      });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 px-0">
          <div className="form-bg">
            <div className="form-card p-4 col-md-4">
              <div className="col-md-12">
                <div className="forgot-password-card-header">
                  <div className="lock-icon">
                    <VscLock />
                  </div>
                  <p>Trouble Logging In</p>
                  <p>
                    Enter your email and we'll send you a link to get back into
                    your account.
                  </p>
                </div>
              </div>

              <div className="col-md-12 mt-4">
                <div className="form-body">
                  <Form
                    name="normal_login"
                    className="login-form"
                    form={form}
                    onFinish={onFinish}
                  >
                    <div className="col-md-12">
                      <Form.Item
                        name="email"
                        className="form-item"
                        rules={[
                          {
                            required: true,
                            message: "Please input your email!",
                            whitespace: true
                          }
                        ]}
                      >
                        <Input placeholder="Email" />
                      </Form.Item>
                    </div>

                    <div className="col-md-12 text-center">
                      <Form.Item>
                        <Button
                          type="primary"
                          className="form-btn border-0"
                          htmlType="submit"
                          to="/"
                        >
                          {loading?<LoadingSpinner/>:"Send login link"}
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="col-md-12">
                <Link to="/login" className="font-black fw-500"><BiArrowBack/> Back to Login</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
