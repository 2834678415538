/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Form,Input, Select, message, Table, Pagination ,Switch , Image, Button, Modal} from "antd";
import { useDispatch } from "react-redux";
import { ActionTypes } from "../../../redux/constants/ActionTypes";
import  moment  from 'moment';
import { CSVLink } from "react-csv";
import {BiExport} from "react-icons/bi"

const { Option } = Select;

function TripDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  var isLoggedIn = localStorage.getItem("loggedIn")
  
  var data = null

  if(isLoggedIn)
  {
    data = JSON.parse(localStorage.getItem("adminInfo"))
  }
  else
  {
    localStorage.clear();
    dispatch({ type: ActionTypes.ADMIN_LOGOUT });
    navigate("/");
  }


  const columns = [
    {
      title: 'S_No',
      key: 'sno',
      width: '20px',
      render: (text, record, index) =>{return (page - 1) * 10 + index + 1}
    
    },
    {
      title: "Truck",
      dataIndex: "truck",
      key: "truck_no",
      render : (text,record,index) =>(
      
      <Link to = {`/tripinfo/${record._id}`} >{<span>{record.truck[0] } {record.redirected?<span className={`badge rounded-pill ${record.status === "complete"?'bg-success': record.status==="cancel"?'bg-danger':'bg-info'} font-white trounded-pill`}>Redirected</span> : ""}</span> } </Link> )  
    },
    {
      title: "Firm",
      dataIndex: "firm",
      key: "firm_no",
      render : (text,record,index) =>{ return record.firm[0] }
    },
    {
      title : "Trip/Route",
      dataIndex : "trip" ,
      key : "trip",
      render : (text,record) =>(<span className = "text-capitalize">{record.trip? record.trip :false}</span>)
    },
    {
      title : "MR No",
      dataIndex : "mr_no" ,
      key : "mr_no"
    }, 
    {
      title : "TP No",
      dataIndex : "tp_no" ,
      key : "tp_no"
    },

      {
        title : "TP Weight",
        dataIndex : "tp_weight" ,
        key : "tp_weight",
        render : (text,record) =>(<span className = "text-uppercase">{record.tp_weight? record.tw_measure ? record.tp_weight+ " " + record.tw_measure: record.tp_weight :false}</span>)
      },
      {
        title : "Status",
        dataIndex : "status",
        key : "status",
        render : (text,record) =>(


               
                <span className={`${record.status === "cancel" ? 'text-danger' : 'text-success'}  fw-500 text-capitalize`}>{record.status}</span>
               
              

           )
      },
  ];

  const [truckData, setTruckData] = useState([]);

  const[csvData, setcsvData] = useState([])

  const [sortingOrder,setSortingOrder] = useState("desc")

  const[query,setQuery] = useState("")

  const [filterValue, setFilterValue] = useState("truckDetails.truck_number");

  const [sortBy, setSortBy] = useState("createdAt");


  const queryPage = useLocation().search.match(/page=([0-9]+)/, "");
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const [page, setPage] = useState(currentPage);
  // eslint-disable-next-line no-unused-vars
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(1);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const[cancelRowId,setCancelRowId] = useState("")

  
  //ON PREVIEW EDIT MODAL
  const showCancelModal = (cId) => {
        setIsModalVisible(true);
        setCancelRowId(cId)
      };
    
      //ON CLOSE EDIT MODAL
      const handleModalCancel = () => {
        setIsModalVisible(false);
        setCancelRowId("")
      };

  const pageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPage) {
      currentPage !== newPage && navigate(`/tripdetails?page=${newPage}`);
      setPage(newPage);
      //       fetchData();
    }
  };


  const onExportCsv = () =>{

    if(truckData){

      if(truckData.length > 0 ) {
        message.success({content:"Data exported.",key:"loading"})
      }

      else{
        message.warning({content:"No data to export!",key:"loading"})
      }

    
    }

  }

  const fetchTripData = () => {
    message.loading({ content: "Loading...", key: "loading" });
    axios
      .post(`${process.env.REACT_APP_API_URL}/admin/fetch_trip_details`, {
        page: page,
        size: pageSize,
        filterColumn:filterValue,
        query:query,
        sortBy : sortBy,
        sortingOrder :sortingOrder,
        id:data.role === "admin" || data.role === "subadmin"? "": data._id

      })
      .then((res) => {
        if (res.data.status) {
          res.data.data.forEach((element, index)=>{
            res.data.data[index].key = index;
          })
          setTruckData(res.data.data);
          setTotalPage(res.data.totalRows);
          message.success({ content: "Loaded", key: "loading" });
        } else {
          message.error({ content: res.data.message, key: "loading" });
        }
      });
  };

  const handleSorting = (value) =>{
    if(value===true){
      setSortingOrder("asc")
    }else{
      setSortingOrder("desc")
    }
  }



  // CANCEL TRIP

  const onCancelTrip = (values) =>{
        axios.post(`${process.env.REACT_APP_API_URL}/admin/cancel_trip`,{id:cancelRowId, cancel_reason: values.cancel_reason, cancel_by : data._id}).then(res =>
          {
          if(res.data.status){
            fetchTripData()
            form.resetFields()
            handleModalCancel()
            message.success({content:res.data.message,key:"loading"})
          }
          else{
            message.error({content:res.data.message,key:"loading"})
          }
        })
      }

  useEffect(() => {
        fetchTripData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page,filterValue,query,sortBy,sortingOrder]);
  
  useEffect(()=>{
    var truckCsvData = []

    truckData.forEach((element,key)=>{

        truckCsvData.push({

        s_no:key+1,
        mr_no:element.mr_no,
        loading_time:element.createdAt?moment(element.createdAt).format('DD-MM-YYYY , hh:mm:ss'):"",
        supplier:element.supplier,
        invoice_no:element.invoice_no,
        material_details:element.material_details,
        material_type:element.material_type,
        tp_weight:element.tp_weight,
        tp_weight_measurement:element.tw_measure,
        loading_weight:element.loading_weight,
        loading_weight_measurement:element.lw_measure,

        transporter_name:element.transporter_name,

        truck:element.truck,

        unloading_weight:element.unloading_weight,
        unloading_weight_measurement:element.uw_measure,
        accepted_weight:element.aw_measure,
        accepted_weight_measurement:element.aw_measure,

        trip:element.trip,

        unloading_time:element.unloading_time,

        tp_no:element.tp_no,

        firm : element.firm,

        truck_type:element.truck_type,

        cancel_at:element.cancel_at?moment(element.cancel_at).format('DD-MM-YYYY , hh:mm:ss'):"",

        cancel_by:element.cancel_by,

        redirectedBy:element.redirectBy,
        redirect_time:element.on_site_cancel_time?moment(element.on_site_cancel_time).format('DD-MM-YYYY , hh:mm:ss'):"",
        redirected_from:element.redirected_from,
        redirect_reason:element.on_site_cancel_reason,

        loaded_by:element.loaded_by,
        
        dispatch_time:element.createdAt?moment(element.dispatch_time).format('DD-MM-YYYY , hh:mm:ss'):"",
        dispatch_by:element.dispatch_by,
       
        arrival_time:element.arrived_at?moment(element.arrived_at).format('DD-MM-YYYY , hh:mm:ss'):"",
        arrival_by:element.arrival_marked_by,
        
        unload_by:element.unload_by?moment(element.unload_by).format('DD-MM-YYYY , hh:mm:ss'):"",
       
        status:element.status,

      })
    })

    setcsvData(truckCsvData)
  },[truckData])
  return (
    <div className="container-fluid">
        <Modal
                title="Cancel Trip"
                visible={isModalVisible}
                onCancel={handleModalCancel}
                footer={false}
                >
                        <Form
                        form={form}
                        name="form"
                        onFinish={onCancelTrip}
                        layout="vertical"
                        autoComplete="off"
                        >
                                <div className="row">
                        <div className="col-md-12">
                        <Form.Item
                        label="Reason for cancel"
                        name="cancel_reason"
                        rules={[
                        {
                        required: true,
                        message: "Please reason for cancel!"
                        }
                        ]}
                        >
                        <Input placeholder="Enter Reason for cancel"></Input>

                        </Form.Item>
                        </div>          
                        </div>
                        <div className="row">
                                <div className="col-md-12">
                        <Form.Item> 
                        <Button htmlType="submit" className="btn text-white border-0 bg-orange">OK</Button>
                        </Form.Item>
                        </div>
                        </div>

                        </Form>

      </Modal>

      <div className="bg-white p-3">
      <div className="card-heading">
                <p>Trip Details</p>
      </div>
      <div className="row my-3">
        <div className="col-md-3">
          <label className ="custom-label">Sort By :</label>
          <Select
            onChange={(value) => setSortBy(value)}
            placeholder ="Sort By:"
            style={{ width: "100%" }} 
            value ={sortBy}
          >
            <Option value ="createdAt">Loaded At</Option>
            <Option value="mr_no">MR No</Option>
            <Option value="tp_no">TP No</Option>
            <Option value="tp_weight">TP weight</Option>
            {data.role==="admin" || data.role === "subadmin" ?<Option value="unloading_time">Completed At</Option>:""}
            {data.role==="admin" || data.role === "subadmin" ?<Option value="cancel_at">Canceled At</Option>:""}
          </Select>
        </div>
        <div className="col-md-3 ">
        <label className ="custom-label">Sorting order :</label>
        <Switch
              checkedChildren={sortingOrder}
              unCheckedChildren={sortingOrder}
              className="d-block"
              // defaultChecked
              onChange={handleSorting}/>
                    
        </div>
        <div className="col-md-3">
        <label className="custom-label">filter By:</label>
          <Select
            onChange={(value) => setFilterValue(value)}
            placeholder ="Filter By:"
            style={{ width: "100%" }} 
            value ={filterValue}
          >
          <Option value="truckDetails.truck_number">Truck</Option>
          <Option value="truckDetails.transporter_name">Transporter Name</Option>
            <Option value="firmDetails.name">Firm</Option>
          
            <Option value="trip">Trip/Route</Option>
            <Option value="mr_no">MR No</Option>
            <Option value="tp_no">TP No</Option>
            <Option value="tp_weight">TP Weight</Option>
            <Option value="status">Status</Option>
            {data.role==="admin" || data.role === "subadmin" ?<Option value="unloadBy.name">Complete By</Option>:""}
            {data.role==="admin" || data.role === "subadmin" ?<Option value="cancelBy.name">Cancel By</Option>:""}
          </Select>
        </div>
          <div className="col-md-3">
            <label className="custom-label">Type Query :</label>
            <Input type="text" placeholder="Type Query..." onChange ={(e)=>setQuery(e.target.value)}/>
          </div>
      </div>
      <div className="row">
        {
          data ?
          data.role === "admin" || data.role === "subadmin" ?
          <div className="col-md-12 text-end mb-2">
          {csvData.length > 0 ? <CSVLink
            data={csvData}
            onClick={onExportCsv}
            filename="tripDetails.csv"
            className="btn btn-sm btn-outline-dark"
          >
            <BiExport className="mb-1 fs-6" /> Export CSV
          </CSVLink> : <button className="btn btn-sm btn-outline-dark" onClick={onExportCsv}> <BiExport className="mb-1 fs-6" /> Export CSV</button>}
        </div>:"":""

        }

        <div className="col-md-12">
          <Table
            className="bg-white"
            columns={columns}
            dataSource={truckData}
            pagination={false}
            style={{ overflow: "auto" }}
            size="small"
            expandable={ data.role === "admin" ?{

              expandedRowRender: record =><div className = "row text-center fw-500 mx-0">


                {
                   record.status === "complete" ?
                   <div className="col-md-4 mx-auto">
                   <p> <span>Completed At : </span><span> {record.unloading_time ? moment(record.unloading_time).format('DD-MM-YYYY hh:mm:ss'): null}</span></p> 
                   <p> <span>Completed By : </span><span className="text-capitalize"> { record.unload_by ?record.unload_by[0]? record.unload_by[0]:null :null}</span></p> 
                   </div>:""
                }
               

                {
                  record.status === "cancel" ?
                  <div className="col-md-4 mx-auto">
                  <p> <span>Canceled At : </span><span> {record.cancel_at ? moment(record.cancel_at).format('DD-MM-YYYY hh:mm:ss'): null}</span></p> 
                  <p> <span>Cancel By : </span><span className="text-capitalize"> { record.cancel_by ?record.cancel_by[0]? record.cancel_by[0]:null :null}</span></p> 
                  <p> <span>Cancel Reason : </span><span className="text-capitalize"> { record.cancel_reason ?record.cancel_reason:null }</span></p> 
                  </div>

                  :null
                }

                </div>
            }: " "}
          />
        </div>
        <div className="col-md-12">
          <Pagination
            className="bg-white p-2 mt-3 text-center"
            // showSizeChanger
            // onShowSizeChange={onShowSizeChange}
            onChange={pageChange}
            defaultCurrent={1}
            total={totalPage}
          />
        </div>
      </div>
        
      </div>
    </div>
  );
}

export default TripDetails;
