import React, { useState,useEffect } from "react";
import { Form, Input, Button, message, Upload, Modal } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ActionTypes } from "./../../redux/constants/ActionTypes";
import FirmDataTable from './../../components/FirmDataTable';
// import { firmDataAction } from './../../redux/actions/DataAction';
import {firmDataAction} from './../../redux/actions/DataAction'
import LoadingSpinner from "../../components/LoadingSpinner";


function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function FirmMangement() {
  const [form] = Form.useForm();

  const [fileList, setFileList] = useState([]);

  const [loading, setLoading] = useState(false);
//   const [firmData,setFirmData] = useState([])

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  var isLoggedIn = localStorage.getItem("loggedIn");

  var data = null;
  if (isLoggedIn) {
    // eslint-disable-next-line no-unused-vars
    data = JSON.parse(localStorage.getItem("adminInfo"));
  } else {
    localStorage.clear();
    dispatch({ type: ActionTypes.ADMIN_LOGOUT });
    navigate("/");
  }

    

    


  const onFinish = (values) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("name", values.name.trim());
    formData.append("email", values.email.trim());
    formData.append("mobile",values.mobile? values.mobile.trim() : "");
    formData.append("address", values.address ? values.address.trim() : "");

    if (values.logo) {
      formData.append("logo", values.logo.file);
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}/admin/add_firm`, formData)
      .then((res) => {
        if (res.data.status) {
          message.success({ content: res.data.message, key: "some" });
          setFileList([]);
          form.resetFields();
          setLoading(false);
	        fetchFirmData()
          // alert(res.data.message)
        } else {
          message.error({ content: res.data.message, key: "some" });
          setLoading(false);
        }
      });
  };

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.preview);
    setPreviewVisible(true);
  };

  const handleCancel = () => setPreviewVisible(false);

  const fetchFirmData = () => {
	axios
	  .get(`${process.env.REACT_APP_API_URL}/admin/get_firmData`)
	  .then((res) => {
      console.log(res.data)
	    if (res.data.status) {
        res.data.data.forEach((element, index)=>{
          res.data.data[index].key = index;
        })
	    	dispatch(firmDataAction(res.data.data));
	    } else {
	      message.error({content: res.data.message ,key : "some"});
	    }
	  });
      };


      useEffect(()=>{
	fetchFirmData()
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])


  return (
    <div className="container">
      <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
      <div className="row">
        <div className="col-lg-4 mt-3">
          <div className="form-card bg-white">
            <div className="card-heading">
              <p>Add New Firm</p>
            </div>
            <div>
              <Form
                form={form}
                name="form"
                initialValues={{
                  remember: true
                }}
                onFinish={onFinish}
                layout="vertical"
                autoComplete="off"
              >
                <div className="row">
                  <div className="col-md-12">
                    <Form.Item 
                    
                    label="Firm Logo"
                    name = "logo"
                    rules= {[
                      {
                        required:true,
                        message : "Please upload firm logo!"
                      }
                    ]}
                    >
                     
                        <Upload
                        accept="image/*"
                          listType="picture-card"
                          fileList={fileList}
                          onPreview={handlePreview}
                          onChange={onChange}
                          beforeUpload={() => false}
                        >
                          {fileList.length < 1 && "+ Upload"}
                        </Upload>
                    </Form.Item>
                  </div>
                  <div className="col-md-12 ">
                    <Form.Item
                      label="Firm Name"
                      name="name"
                      rules={[
                        {
                          pattern:/^[a-zA-Z_ ]+$/,
                          message : "Firm name must contain alpha characters only!"
                        },
                        {
                          required: true,
                          message: "Please input firm name!"
                        }
                      ]}
                    >
                      <Input placeholder="Enter Firm Name" />
                    </Form.Item>
                  </div>
                </div>

              
              
                <div className="col-md-12 ">
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "Please input valid email!"
                      },
                      {
                        required: true,
                        message: "Please input your email!"
                      }
                    ]}
                  >
                    <Input placeholder="Enter Your Email" />
                  </Form.Item>
                </div>

                <div className="col-md-12 ">
                    <Form.Item
                      label="Mobile"
                      name="mobile"
                      rules={[
                        {
                          pattern: /^[0-9]{10}$/,
                          message: "Input valid mobile number!"
                        }
                      ]}
                    >
                      <Input type = "number" placeholder="Enter Firm Mobile No. " />
                    </Form.Item>
                  </div>
                <div className="col-md-12 ">
                    <Form.Item
                      label="Firm Address"
                      name="address"
                      rules={[
                        {
                          pattern:/^[a-zA-Z0-9_ , ]+$/,
                          message : "Firm Address can contain alphanumeric characters only!"
                        },
                      ]}
                    >
                      <Input.TextArea autoSize={{ minRows: 3, maxRows: 3 }} placeholder="Enter Firm Address " />
                    </Form.Item>
                  </div>

                <div className="col-md-6">
                  <Form.Item>
                    <Button htmlType="submit" className="form-btn border-0">
                      {loading ?<LoadingSpinner/> : "Add"}
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>
        </div>

        <div className="col-lg-8 mt-3">
		<FirmDataTable/>
	</div>
      </div>
    </div>
  );
}

export default FirmMangement;
