import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Input,
  Select,
  message,
  Table,
  Image,
  Button,
  Pagination,
  Switch,
  Popconfirm,
} from "antd";
import moment from "moment";
import { AiOutlineDelete } from "react-icons/ai";
import { ActionTypes } from "./../../../redux/constants/ActionTypes";
import { FiEdit } from "react-icons/fi";

const { Option } = Select;

function UserDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const columns = [
    {
      title: "S_No",
      key: "sno",
      width: "20px",
      render: (text, record, index) => (
        <span key={index}>{(page - 1) * 10 + index + 1}</span>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => (
        <span key={index}>
          <div
            className={`status-badge ${
              record.login_status ? "active-badge" : "inactive-badge"
            }`}
          ></div>
          {text}
        </span>
      ),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Date of Birth",
      dataIndex: "dob",
      key: "dob",
      render: (text, record) => (
        <span key={record._id}>
          {record.dob ? moment(record.dob).format("DD-MM-YYYY") : null}
        </span>
      ),
    },
    {
      title: "Profile Pic",
      dataIndex: "pp",
      key: "profilePic",
      render: (text, record) =>
        record.profilePic ? (
          <Image
            key={record._id}
            src={`${process.env.REACT_APP_API_URL}/${record.profilePic}`}
            width={"60px"}
            height={"70px"}
            style={{ objectFit: "contain" }}
          />
        ) : null,
    },

    {
      title: "Action",
      dataIndex: "",
      key: "action",
      render: (text, record) => (
        <div key={record._id}>
          <Button
            className="btn btn-outline-primary btn-sm fw-500 me-2"
            onClick={() => navigate(`/edituser/${record._id}`)}
          >
            <FiEdit />
          </Button>
          <Popconfirm
            title="Are you sure to delete this user?"
            description="Are you sure to delete this user?"
            onConfirm={() => deleteUser(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              className="btn btn-sm fw-500 me-2"
            >
              <AiOutlineDelete
                className="font-red fs-5"
                style={{ cursor: "pointer" }}
              />
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const [userData, setUserData] = useState([]);

  const [sortingOrder, setSortingOrder] = useState("desc");

  const [query, setQuery] = useState("");

  const [filterValue, setFilterValue] = useState("name");

  const [sortBy, setSortBy] = useState("createdAt");

  var isLoggedIn = localStorage.getItem("loggedIn");

  const queryPage = useLocation().search.match(/page=([0-9]+)/, "");
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const [page, setPage] = useState(currentPage);
  // eslint-disable-next-line no-unused-vars
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(1);

  const pageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPage) {
      currentPage !== newPage && navigate(`/userdetails?page=${newPage}`);
      setPage(newPage);
      //       fetchData();
    }
  };

  var data = null;

  if (isLoggedIn) {
    data = JSON.parse(localStorage.getItem("adminInfo"));
  } else {
    localStorage.clear();
    dispatch({ type: ActionTypes.ADMIN_LOGOUT });
    navigate("/");
  }

  // FIRM DELETE
  const deleteUser = (userId) => {
    message.loading({ content: "Delete...", key: "data" });
    axios
      .post(`${process.env.REACT_APP_API_URL}/admin/delete_admin`, {
        id: userId,
      })
      .then((res) => {
        if (res.data.status) {
          fetchUserData();
          message.success({ content: "Deleted", key: "data" });
        } else {
          message.error({ content: "Failed to delete user!", key: "data" });
        }
      });
  };

  const fetchUserData = () => {
    message.loading({ content: "Loading...", key: "data" });
    axios
      .post(`${process.env.REACT_APP_API_URL}/admin/fetch_admin`, {
        id: data._id,
        page: page,
        size: pageSize,
        filterColumn: filterValue,
        query: query,
        sortBy: sortBy,
        sortingOrder: sortingOrder,
      })
      .then((res) => {
        if (res.data.status) {
          setUserData(res.data.data);
          setTotalPage(res.data.totalRows);
          if (page > 1 && res.data.data.length === 0) {
            navigate(`/userdetails?page=${page - 1}`);
            setPage(page - 1);
          }
          message.success({ content: "Loaded", key: "data" });
        } else {
          message.error({ content: res.data.message, key: "data" });
        }
      });
  };

  const handleSorting = (value) => {
    if (value === true) {
      setSortingOrder("asc");
    } else {
      setSortingOrder("desc");
    }
  };

  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filterValue, query, sortBy, sortingOrder]);
  // const onShowSizeChange = (current, pageSize) => {
  //   console.log()
  // };

  return (
    <div className="container-fluid">
      <div className="bg-white p-3">
        <div className="card-heading">
          <p>User Details</p>
        </div>
        <div className="row my-3">
          <div className="col-md-3">
            <label className="custom-label">Sort By :</label>
            <Select
              onChange={(value) => setSortBy(value)}
              placeholder="Sort By:"
              style={{ width: "100%" }}
              value={sortBy}
            >
              <Option value="createdAt">Default</Option>
              <Option value="name">Name</Option>
              <Option value="role">Role</Option>
            </Select>
          </div>
          <div className="col-md-3 ">
            <label className="custom-label">Sorting order :</label>
            <Switch
              checkedChildren={sortingOrder}
              unCheckedChildren={sortingOrder}
              className="d-block"
              // defaultChecked
              onChange={handleSorting}
            />
          </div>
          <div className="col-md-3">
            <label className="custom-label">filter By:</label>
            <Select
              onChange={(value) => setFilterValue(value)}
              placeholder="Filter By:"
              style={{ width: "100%" }}
              value={filterValue}
            >
              <Option value="name">Name</Option>
              <Option value="role">Role</Option>
              <Option value="email">Email</Option>
              <Option value="mobile">Mobile</Option>
            </Select>
          </div>
          <div className="col-md-3">
            <label className="custom-label">Type Query :</label>
            <Input
              type="text"
              placeholder="Type Query..."
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Table
              rowKey="_id"
              className="bg-white"
              columns={columns}
              dataSource={userData}
              pagination={false}
              size="small"
              style={{ overflow: "auto" }}
            />
          </div>
          <div className="col-md-12">
            <Pagination
              className="bg-white p-2 mt-3 text-center"
              showSizeChanger={false}
              onChange={pageChange}
              defaultCurrent={1}
              total={totalPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserDetails;
