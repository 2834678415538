import { ActionTypes } from "../constants/ActionTypes";


const initialState = {
        firmData : [],
        waitingData : []
}

export const firmDataReducer = (state = initialState.firmData , {type,payload}) =>{
        switch(type){
                case ActionTypes.FIRM_DATA :
                        return {...state,data:payload}
                default :
                        return {...state};
        }
}

export const waitingTruckDataReducer = (state = initialState.waitingData , {type,payload}) =>{
        switch(type){
                case ActionTypes.WAITING_TRUCK_DATA :
                        return {...state,data:payload}
                default :
                        return {...state};
        }
}