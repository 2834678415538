import React, { useContext, useEffect, useState } from "react";
import { UserOutlined } from "@ant-design/icons";
import { GiFactory } from "react-icons/gi";
import { MdClose } from "react-icons/md";
import { AiOutlineUserAdd } from "react-icons/ai";
import { HiUserGroup } from "react-icons/hi";
import { BsTruck, BsTruckFlatbed } from "react-icons/bs";
import { FaTruckLoading } from "react-icons/fa";
import { MdOutlineDashboard } from "react-icons/md";
import { IoDownloadOutline } from "react-icons/io5";
import { FaRoad } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { BiDetail, BiAddToQueue, BiMessageAltDots } from "react-icons/bi";
import { useLocation } from "react-router-dom";
import { message } from "antd";
import { Layout, Menu } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import { SocketContext } from "../Socket/Socket";

const { SubMenu } = Menu;
const { Sider } = Layout;

function Sidebar() {
  const dispatch = useDispatch();

  const location = useLocation();

  const socket = useContext(SocketContext);

  const [currentLocation, setCurrentLocation] = useState(location.pathname);

  // const navigate = useNavigate()

  const sidebar = useSelector((state) => state.sidebar.sidebar_status);
  const adminData = useSelector((state) => state.auth.admin);
  const unseenMessage = useSelector(
    (state) => state.unseenMessage.unseen_message
  );

  // const [unseenMessage,setUnseenMessage] = useState()

  //TOGGLE SIDEBAR

  const setSidebarStatus = () => {
    if (sidebar === "close") {
      dispatch({ type: "SET_SIDEBAR_STATUS", payload: "open" });
    } else {
      dispatch({ type: "SET_SIDEBAR_STATUS", payload: "close" });
    }
  };

  //CLOSE SIDEBAR ON NAVIGATION

  const closeOnNavigate = () => {
    if (window.innerWidth <= 1200) {
      dispatch({ type: "SET_SIDEBAR_STATUS", payload: "close" });
    }
  };
  useEffect(() => {
    return setCurrentLocation(location.pathname);
  }, [location]);
  const unseenMessagCount = () => {
    if (adminData.role === "supervisor") {
      axios
        .post(`${process.env.REACT_APP_API_URL}/admin/unseenmessage`, {
          id: adminData._id,
        })
        .then((res) => {
          if (res.data.status) {
            dispatch({ type: "UNSEEN_MESSAGE", payload: res.data.data });
            if (res.data.data.length > 0) {
              message.success(`You have ${res.data.data} new message`);
            }

            // setUnseenMessage(res.data.data)
          } else {
            message.error(res.data.message);
          }
        });
    }
  };

  useEffect(() => {
    socket.on("message", () => {
      unseenMessagCount();
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  useEffect(() => {
    unseenMessagCount();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`sider-wrapper`}>
      <Sider>
        <div className={`sidebar-top`}>
          <div className="side-logo py-1">
            <img
              src="/images/logo.png"
              alt="Logo"
              className="img-fluid"
              // style={{ width: "300px" }}
            />
            {/* <p className='text-center mb-0'>Aggregate</p>  */}
            <div className="sidebar-close-button" onClick={setSidebarStatus}>
              <MdClose />
            </div>
          </div>

          <div className="container py-3">
            <div className="d-flex">
              <div className="side-profile-img">
                <img
                  src={
                    adminData.profilePic
                      ? process.env.REACT_APP_API_URL +
                        "/" +
                        adminData.profilePic
                      : "/images/Default-registration-profile.png"
                  }
                  className="bg-white"
                  alt="admin-profile"
                />
              </div>
              <div className="ms-3 align-items-center">
                <p className="mb-0 side-profile-greetings text-capitalize ">
                  {" "}
                  {adminData.name ? adminData.name : ""}
                </p>
                <p className="mb-0 text-capitalize side-profile-details">
                  {adminData.role ? adminData.role : ""}
                </p>
              </div>
            </div>
          </div>
        </div>
        <Menu
          mode="inline"
          defaultSelectedKeys={currentLocation}
          // defaultOpenKeys={['/']}
          style={{ height: "100%" }}
          className={`sidemenu-style`}
          selectedKeys={currentLocation}
        >
          <Menu.Item key="/" icon={<MdOutlineDashboard />}>
            <Link to="/" onClick={closeOnNavigate}>
              {" "}
              Dashboard{" "}
            </Link>
          </Menu.Item>
          {adminData.role === "admin" ? (
            <>
              <SubMenu
                key="sub1"
                icon={<UserOutlined />}
                title="User Management"
              >
                <Menu.Item key="/addUser" icon={<AiOutlineUserAdd />}>
                  <Link to="/addUser" onClick={closeOnNavigate}>
                    Add User
                  </Link>
                </Menu.Item>
                <Menu.Item key="/userdetails" icon={<HiUserGroup />}>
                  <Link to="/userdetails" onClick={closeOnNavigate}></Link>User
                  Details
                </Menu.Item>
              </SubMenu>
            </>
          ) : null}


          {adminData.role === "admin" || adminData.role === "subadmin" ? (
            <Menu.Item key="/firm" icon={<GiFactory />}>
              <Link to="/firm" onClick={closeOnNavigate}>
                Firm Management
              </Link>
            </Menu.Item>
          ) : null}

          {adminData.role === "admin" ? (
            <Menu.Item key="/materialinfo" icon={<TiTick />}>
              {" "}
              <Link to="/materialinfo" onClick={closeOnNavigate}>
                Material Info
              </Link>{" "}
            </Menu.Item>
          ) : null}

         
          <SubMenu key="sub2" icon={<BsTruck />} title="Truck Management">
            {adminData.role === "supervisor" ||
            adminData.role === "subadmin" ? (
              <Menu.Item key="/addtruck" icon={<BiAddToQueue />}>
                <Link to="/addtruck" onClick={closeOnNavigate}>
                  Add Truck
                </Link>{" "}
              </Menu.Item>
            ) : null}

            {adminData.role === "admin" ? (
              <Menu.Item key="/waitingtruck" icon={<BsTruckFlatbed />}>
                <Link to="/waitingtruck" onClick={closeOnNavigate}>
                  Waiting Trucks
                </Link>
              </Menu.Item>
            ) : null}

            <Menu.Item key="/truckdetails" icon={<BiDetail />}>
              <Link to="/truckdetails" onClick={closeOnNavigate}>
                Truck Details
              </Link>{" "}
            </Menu.Item>
          </SubMenu>

          <SubMenu key="sub3" icon={<FaTruckLoading />} title="Trip Management">
            {
              //  adminData.role === "supervisor" ?
              adminData.role !== "admin" ? (
                <Menu.Item key="/loadtrip" icon={<BiAddToQueue />}>
                  <Link to="/loadtrip" onClick={closeOnNavigate}>
                    Add Trip
                  </Link>{" "}
                </Menu.Item>
              ) : null
            }

            <Menu.Item key="/readytrips" icon={<TiTick />}>
              {" "}
              <Link to="/readytrips" onClick={closeOnNavigate}>
                Ready Trips
              </Link>{" "}
            </Menu.Item>

            <Menu.Item key="/onwaytrips" icon={<FaRoad />}>
              {" "}
              <Link to="/onwaytrips" onClick={closeOnNavigate}>
                On The Way
              </Link>{" "}
            </Menu.Item>

            <Menu.Item key="/arrivedtrips" icon={<IoDownloadOutline />}>
              {" "}
              <Link to="/arrivedtrips" onClick={closeOnNavigate}>
                Arrived Trips
              </Link>{" "}
            </Menu.Item>

            <Menu.Item key="/tripdetails" icon={<BiDetail />}>
              {" "}
              <Link to="/tripdetails" onClick={closeOnNavigate}>
                Trip Details
              </Link>
            </Menu.Item>
          </SubMenu>

          {adminData.role === "subadmin" ? (
            <Menu.Item key="/sendmessage" icon={<BiMessageAltDots />}>
              {" "}
              <Link to="/sendmessage" onClick={closeOnNavigate}>
                Send Message
              </Link>{" "}
            </Menu.Item>
          ) : null}

          {adminData.role === "supervisor" ? (
            <Menu.Item key="/message" icon={<BiMessageAltDots />}>
              {" "}
              <Link to="/message" onClick={closeOnNavigate}>
                {" "}
                Message{" "}
                {unseenMessage > 0 ? (
                  <span className="badge badge-sm bg-orange rounded-pill">
                    {unseenMessage}
                  </span>
                ) : (
                  ""
                )}
              </Link>{" "}
            </Menu.Item>
          ) : null}
        </Menu>
      </Sider>
    </div>
  );
}

export default Sidebar;
