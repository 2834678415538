import React, { useContext, useEffect, useState } from "react";
import { Dropdown, Menu, message } from "antd";
import { AiOutlineMenuFold } from "react-icons/ai";
import { IoMdNotificationsOutline } from "react-icons/io";
import { RiKeyFill } from "react-icons/ri";
import { FiUser, FiLogOut, FiTruck } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { ActionTypes } from './../redux/constants/ActionTypes';
import axios from "axios";
import { SocketContext } from './../Socket/Socket';
import moment from "moment";


function Navbar() {


  const dispatch = useDispatch()
  const navigate = useNavigate();

  const socket = useContext(SocketContext)


  const sidebar = useSelector(state => state.sidebar.sidebar_status)
  const adminData = useSelector(state => state.auth.admin)

  const [notifications, setNotification] = useState()




  const fetchNotification = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/admin/fetch_notification`, {
      senderId: adminData ? adminData._id : "", role: adminData ? adminData.role : ""
    }).then(res => {

      if (res.data.status) {

        setNotification(res.data.data)
      }
      else {
        message.error(res.data.message)
      }

    })
  }
  useEffect(() => {

    socket.on("notify", () => {
      fetchNotification()
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket])


  useEffect(() => {

    fetchNotification()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  // TOGGLE SIDEBAR

  const onClickHandler = () => {
    if (sidebar === "close") {
      dispatch({ type: "SET_SIDEBAR_STATUS", payload: "open" })
    }
    else {
      dispatch({ type: "SET_SIDEBAR_STATUS", payload: "close" })
    }
  }

  const logOutHandler = (event) => {
    event.preventDefault()
    if (window.confirm("Are You sure to logout")) {

      axios.post(`${process.env.REACT_APP_API_URL}/admin/logout_admin`, { id: adminData._id }).then(res => {
        if (res.data.status) {
          localStorage.clear();
          dispatch({ type: ActionTypes.ADMIN_LOGOUT });
          navigate("/login");
        }
        else {
          message.error(res.data.message)
        }
      })
    }
  }


  const onNotificationClick = (notifyid, seen_by) => {

    var seenBy = seen_by
    seenBy.push(adminData ? adminData._id : "")
    axios.post(`${process.env.REACT_APP_API_URL}/admin/update_seen_notification`, { nId: notifyid, seen_by: seenBy }).then(res => {
      if (res.data.status) {
        fetchNotification()
      }
      else {
        message.error(res.data.message)
      }
    })
  }


  const notificationMenu = (

    <Menu className="nav-notification-menu">
      <Menu.Item key="0" disabled className="notification-menu-heading">
        <div className="dd-menu-title">
          <p className="text-center mb-0">
            Notifications
          </p>
        </div>
      </Menu.Item>

      {
        notifications ? notifications.length > 0 ? notifications.map((element, index) => {
          return <Menu.Item key={index + 1} onMouseLeave={() => onNotificationClick(element._id, element.seen)}>
            <div className="container">
              <div className="row">
                <div className="col-3 dd-avatar d-flex align-items-center ">
                  <FiTruck />
                </div>
                <div className="col-9 px-0">
                  <div className="d-flex justify-content-between"><span className="text-capitalize fw-500">{element.title}</span><span className="fs-sm">{element.createdAt ? moment(element.createdAt).local().startOf('seconds').fromNow() : ""}</span></div>
                  <p className="dd-content mb-2 fs-sm textcolor-secondary">{
                    element.description
                  }</p>
                </div>
              </div>

            </div>
            <div>
            </div>
          </Menu.Item>
        }) :
          <Menu.Item key={"none"} >
            <div className="container">
              <div className="row">

                <div className="col-12 px-0">
                  <p className="dd-content mb-2 fs-sm textcolor-secondary text-center">
                    No Notifications
                  </p>
                </div>
              </div>

            </div>
            <div>
            </div>
          </Menu.Item> : ""
      }



    </Menu>

  );

  const profileMenu = (
    <Menu style={{ width: "300px", position: "relative" }}>
      <Menu.Item key="0" disabled style={{ cursor: "default", color: "black" }}>
        <div className="container p-0">
          <div className="d-flex dd-profile-info">
            <div className="d-flex align-items-center admin-dd-avatar">
              <img src={adminData.profilePic ? process.env.REACT_APP_API_URL + "/" + adminData.profilePic : "/images/Default-registration-profile.png"} alt="ProfileImg" />
            </div>
            <div className="ms-2 d-flex align-items-center">
              <div>
                <p className="mb-0 ">
                  <span className="fw-500 text-capitalize d-block">{adminData.name ? adminData.name : ""}</span>
                  <span className="textcolor-secondary">{adminData.email ? adminData.email : ""}</span>
                </p>
              </div>
            </div>
          </div>

        </div>
        <div>
        </div>
      </Menu.Item>
      <Menu.Item key="1">
        <Link to="/profile" ><FiUser /><span className="ms-3 fs-sm fw-500 textcolor-secondary">My Profile</span> </Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to="/changepassword" ><RiKeyFill /><span className="ms-3 fs-sm fw-500 textcolor-secondary">Change Password</span> </Link>
      </Menu.Item>
      <Menu.Item key="3">
        <p onClick={logOutHandler} ><FiLogOut className="text-danger" /><span className="ms-3 fs-sm fw-500 text-danger">Log Out</span> </p>
      </Menu.Item>

    </Menu>
  );

  return (
    <nav className="container-fluid">
      <div className="d-flex justify-content-between h-100  align-items-center">
        <div className="">
          <span className="side-collapse" >
            <AiOutlineMenuFold className="fs-4" onClick={onClickHandler} />
          </span>
        </div>
        <div className="text-end">
          <ul className="list-inline list-unstyled mb-0">
            <li className="list-inline-item me-4">
              <Dropdown
                overlay={notificationMenu}
                placement="bottomRight"
                trigger={["click"]}
                arrow

              >
                <span className="border-0 fs-4 " style={{ background: "transparent", cursor: "pointer", position: "relative" }}><IoMdNotificationsOutline />

                  {
                    notifications ? notifications.length > 0 ?
                      <span className="notification-badge"></span>
                      : "" : ""
                  }

                </span>

              </Dropdown>
            </li>

            <li className="list-inline-item ">
              <Dropdown
                overlay={profileMenu}
                placement="bottomRight"
                trigger={["click"]}
                arrow
              >
                <span className="border-0 fs-4" style={{ background: "transparent" }}>
                  <span className="">
                    <img src={adminData.profilePic ? process.env.REACT_APP_API_URL + "/" + adminData.profilePic : "/images/Default-registration-profile.png"} alt="adminicon" className="profile-icon-img" />
                  </span>
                </span>
              </Dropdown>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
