import React from 'react';

import {MdOutlineDoubleArrow} from "react-icons/md"
import { Link } from 'react-router-dom';

function DashCard(props) {
        return (
                 <div className="form-card dash-card-wrapper"> 
                        <div className={`dash-card-icon ${props.iconBg}`}>
                                {props.icon}    
                        </div>
                        <div className='dash-card-data'>
                                <p>{props.infoTitle} </p>
                                <p>{props.infoCount}</p>
                        </div>
                        <div className="dash-card-footer ">
                                <Link to = {props.linkPath} className ="font-black fw-500 text-capitalize">{props.linkTitle} <MdOutlineDoubleArrow className='font-black fs-sm'/> </Link>
                               {
                                       props.activeUser ? <p className='d-inline mb-0 text-info fw-500 me-2'>{props.activeUser} Active User  </p> :null
                               }      
                        </div>
                </div>
        );
}

export default DashCard;